import { Brush, ReplayOutlined } from "@material-ui/icons";
import {
  Button
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useRef, useState } from "react";

export default function ReCalculateCostAllocation({
  onUpdate,
  visibleRows
}) {
  const [open, setOpen] = useState(false);
  const handleReCalculate = () => {
    setOpen(true);
  };
  const filteredRows = useRef(visibleRows.filter(row => Boolean(row.costallocationmethod)));

  const handleSubmit = () => {
    setOpen(false);
    onUpdate(filteredRows.current);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        size="small"
        startIcon={<ReplayOutlined />}
        onClick={handleReCalculate}
      >
        ReCalculate
      </Button>
      <Dialog fullWidth maxWidth="xs" open={open}>
        <DialogTitle>Recalculate? </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            This will recalculate all the visible {filteredRows.current ? filteredRows.current.length : 0} rows <br /> <b>(Except rows without Cost Allocation Method)</b>. Are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel}>
            No
          </Button>
          <Button onClick={handleSubmit} variant="contained">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
