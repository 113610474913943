import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import './App.css';
import Apps from "./pages/apps/apps";
import Home from "./pages/home/home";
import Login from "./pages/login/login";
import Po from "./pages/po/po";
import PoDashboard from "./pages/po_dashboard/po_dashboard";
import SchSummaryReport from "./pages/reports/sch_summary_report/sch_summary";


import AssociateAdvanceAnalysis from "./pages/reports/associate_advance_analysis/associate_advance_analysis";
import AttendanceProdReport from "./pages/reports/attn_prod_report/attn_prod_report";
import ClientDataAnalysisReport from "./pages/reports/client_data_analysis/add";
import ClientDataReconcileReport from "./pages/reports/client_data_reconcile/add";


import CreateKG from "./components/Create_kg";
import Sidebar1 from "./components/sidebar2";
import Nopage from "./pages/404/404";
import Confirmation from "./pages/confirmation/confirmation";
import Edureka_OTP_Approval from "./pages/confirmation/Edureka_OTP_Approval";
import FixedAssetDeletion from './pages/fixed_asset_deletion.jsx';
import FixedAssetRegister from './pages/fixed_asset_register';
import AccountReports from "./pages/reports/account_reports/account_reports";
import SchSummaryDetails from "./pages/reports/sch_summary_details_report/sch_summary_details";
import ResetPassword from "./pages/resetPassword/resetPassword";
import SchGenerator from './pages/schedule_generator';
import Transactions from "./pages/transactions/transactions";
import Upload from "./pages/upload/upload";
import AReport from './pages/Edureka/AReport.js';
import DeferredRevenueAndAmortization from './pages/Edureka/DeferredRevenueAndAmortization.jsx';
import SalesReport from './pages/Edureka/SalesReport.jsx';
import Dashboard from './pages/reports/Dashboard';
import RevenueTariff from './pages/revenue_tariff/revenue_tariff';
import RevenueTariffSlab from './pages/revenue_tariff/revenue_tariff_slab';


import { connect } from 'react-redux';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch
} from "react-router-dom";
import Calculator from './pages/Calculator/Calculator';
import AreaManager from './pages/CI/AreaManager';
import CiaCodeAnalysis from './pages/CI/CiaCodeAnalysis';
import CIATrack from './pages/CI/CIATrack';
import Compute from './pages/CI/Compute';
import ConsolidatedDashboard from './pages/CMS/ConsolidatedDashboard';
import DECP from './pages/CMS/DECP';
import EvaluationPredictions from './pages/CMS/EvaluationPredictions';
import EventCapture from './pages/CMS/EventCapture';
import EventHistory from './pages/CMS/EventHistory';
import GetPredictions from './pages/CMS/GetPredictions';
import Predictions from './pages/CMS/Predictions';
import RecommHistory from './pages/CMS/RecommHistory';
import SeqOfEvents from './pages/CMS/SeqOfEvents';
import ItemListReport from './pages/Edureka/ItemListReport.jsx';
import VendorListReport from './pages/Edureka/VendorListReport.jsx';
import Etds from './pages/Etds';
import GenerateComponent from "./pages/generate_components/generate_component";
import DemandPlanning from "./pages/generate_components/generate_form";
import GenerateSection from "./pages/generate_components/generate_section";
import NewDesign from "./pages/generate_components/newDesign";
import GroupingRules from './pages/GroupingRules';
import InspectUpload from './pages/InspectUploads';
import MenuTree from './pages/MenuTree';
import PDM from './pages/PDM';
import PricingSheet from "./pages/PricingSheet";
import TDSRecon from './pages/Recon/TDSRecon';
import FlipkartRecon from './pages/reports/FlipkartRecon';
import ControlTotal from './pages/TDS/ControlTotal';
import CrossTANRecomm from './pages/TDS/CrossTANRecomRun';
import TDSDashboard from './pages/TDS/Dashboard';
import InputOutputRecon from './pages/TDS/InputOutputRecon';
import ProcessRecom from './pages/TDS/ProcessRecom';
import RecomRun from './pages/TDS/RecomRun';
import Reconciliation from './pages/TDS/Reconciliation';
import ReconciliationDetailReport from './pages/TDS/ReconciliationDetailReport';
import ReconciliationSummary from './pages/TDS/ReconciliationSummary';
import ReconStatus from './pages/TDS/ReconStatus';
import ReverseRecom from './pages/TDS/ReverseRecom';
import SRIRun from './pages/TDS/SRIRun';
import TANtoCustomerMapping from './pages/TDS/TANtoCustomerMapping';
import TDSReconSheet from './pages/TDS/TDSReconSheet';
import Schema from './pages/TREE/container';
import CreditCardUpload from './pages/upload/CreditCard_upload';
import Upload2 from './pages/upload/Upload2';
import Upload3 from './pages/upload/Upload3';
import Upload4 from './pages/upload/Upload4';
import VisualRender from "./pages/visualRender";

import DashboardCheck from './pages/TDS/Dashboard';
// import PDFUpload from './pages/TDS/PDFUpload';
// import Secrets from './pages/TDS/Secrets';
import COD from './pages/CI/COD';
import ConfirmationScreen from './pages/CI/ConfirmationScreen';
import DepositHistory from './pages/CI/DepositHistory';
import Textract from './pages/CI/Textract';
// import DashBoard1 from './pages/GoOne/DashBoard1';
import SelectionScreen from './pages/CI/SelectionScreen';
import StationNotPosted from './pages/CI/StationNotPosted';
// import BeforeJSON from './pages/VR/BeforeJSON';
import AgeingReport from './pages/CI/AgeingReport';
import CODSubmissionStatus from './pages/CI/CODSubmissionStatus';
import DocumentWiseComparison from './pages/CI/DocumentWiseComparison';
import LossAndRecovery from './pages/CI/LossAndRecovery';
import RecoveryHistory from './pages/CI/RecoveryHistory';
import RemittanceHistory from './pages/CI/RemittanceHistory';

import Dynamic_Forms from './pages/Dynamic_forms/RecursiveBuilder';
import AccountDetermination_Report from './pages/Edureka/AccountDetermination_Report';
import Amortize_Bill_Report from './pages/Edureka/Amortize_Bill_Report';
import Edureka_Approval from './pages/Edureka/Approval';
import BulkApprovals from './pages/Edureka/BulkApprovals';
import Cost_Allocation_Report from './pages/Edureka/Cost_Allocation_Report';
import EmployeeCostReport from './pages/Edureka/EmployeeCostReport';
import BookClosure from './pages/veranda/BookClosure/Book_Closure';
import LedgerView from './pages/veranda/Ledger/Ledger_View';
// import Pivot_Table from './components/Pivot_Table';
import AdminScreen from './pages/Admin/AdminScreen';
import ExpenseJournalDump from './pages/Edureka/ExpenseJournalDump';
import TallyInputUserSelect from './pages/Edureka/TallyInputUserSelect';

import ExpenseAmortizationReport from './pages/Edureka/ExpenseAmortizationReport';
import ProvisionStatusReport from './pages/Edureka/ProvisionStatusReport';


import AMReport from './pages/ConnectIndiaTable/AMReport';
import AreaManagerDashboard from './pages/ConnectIndiaTable/AreaManagerDashboard';
import AreaManagerHistory from './pages/ConnectIndiaTable/AreaManagerHistory';
import AreaManagerReport from './pages/ConnectIndiaTable/AreaManagerReport';
import BranchManagerHistory from './pages/ConnectIndiaTable/BranchManagerHistory';
import ExceptionReport from './pages/ConnectIndiaTable/ExceptionReport';
import PMReport from './pages/ConnectIndiaTable/PMReport';
import BillAnalysisAndStatusReport from './pages/Edureka/BillAnalysisAndStatusReport';
import CostContributionV2 from './pages/Edureka/CostContributionV2/CostContribution.jsx';
import EmployeeCostSplitup from './pages/Edureka/EmployeeCostSplitup';
import ExtendItemMaster from './pages/Edureka/ExtendItemMaster';
import ExtendVendorMaster from './pages/Edureka/ExtendVendorMaster';
import FunctionalJournal from './pages/Edureka/FunctionalJournal';
import FunctionalProfitAndLoss from './pages/Edureka/FunctionalProfitAndLoss';
import InboxInitialInput from './pages/Edureka/InboxInitialInput';
import InstructorCostAccrualReport from './pages/Edureka/InstructorCostAccrualReport';
import Logs from './pages/Edureka/Logs.js';
import NewFunctionalForFrance from './pages/Edureka/NewFunctionalForFrance';
import NewPaymentScreen from './pages/Edureka/NewPaymentScreen';
import PaymentReport from './pages/Edureka/PaymentReport.js';
import Period_end_process from './pages/Edureka/Period_end_process';
import PeriodCostSheetReport from './pages/Edureka/PeriodCostSheetReport';
import SchemaNew from './pages/schemaFree/container';
import CostContribution from './pages/Edureka/CostContribution/CostContribution.jsx';
import BankStatement from './pages/reports/BankStatement/BankStatement.jsx';
import { ToastContainer } from "react-toastify";

class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      type : ''
    }
  }


  
  setStateToSession = () => {
    localStorage.setItem("store", JSON.stringify(this.props.store))
  }
  
  getUrl = () => {
    const currUrl = window.location.href;
    var a = currUrl.split('//');
    var b = a[1].split("/")
    var type = b[1];
    this.setState({
      type : type
    })
  }
  componentDidMount(){
    this.interval = setInterval(() => {
      this.getUrl();
    }, 1000);
    window.addEventListener('beforeunload', this.setStateToSession);
  }

  componentWillUnmount() {
      this.setStateToSession();
      window.removeEventListener('beforeunload', this.setStateToSession); // remove the event handler for normal unmounting
      clearInterval(this.interval);
  }


    render() {
      return (
        // <div className="main-content">
        <div id="main-content" className={(this.props.store.login.user.login && this.props.store.login.userData.USR_Tenant === "FM00001") ? "main-content-free" : "main-content"}>
        {/* {this.props.store.login.user.login && !window.location.href.includes('Edureka_OTP_Approval') ? 
          <Sidebar1 type={this.state.type} />
          : 
          null} */}

        {this.props.store.login.user.login && !window.location.href.includes('Edureka_OTP_Approval') && 
        (this.props.store.login.user.login && this.props.store.login.userData.USR_Tenant !== "FM00001") ? 
          <Sidebar1 type={this.state.type} />
          : 
          null}
    
      
            <Router>
            { this.props.store.login.user.login && this.props.store.login.auth !== "" ? 
              <>
                <Switch>
                  <Route path="/home">
                    <Home />
                  </Route>
      
                  <Route path="/" exact>
                    <Apps type="main" />
                  </Route>

                  
                  {/* <Redirect to="/" /> */}
      
                  <Route path = "/adminscreen">
                  <AdminScreen/>
                  </Route>

                  <Route path="/payment">
                    <PaymentReport/>
                  </Route>

                  <Route path="/po_home">
                    <Po />
                  </Route>

                  <Route exact path="/Edureka_OTP_Approval/:token">
                    <Edureka_OTP_Approval />
                  </Route>
      
                  <Route path="/pricing_sheet">
                    <PricingSheet />
                  </Route>
      
                  <Route path="/create_kg">
                    <CreateKG />
                  </Route>

                  <Route path ="/agging">
                  <AReport />
                  </Route>
      
                  <Route path="/cia_track">
                    <CIATrack />
                  </Route>
      
                  <Route path="/inspect_uploads">
                    <InspectUpload />
                  </Route>
      
                  <Route path="/grouping_rules">
                    <GroupingRules />
                  </Route>
      
                  <Route path="/flipkart_recon">
                    <FlipkartRecon />
                  </Route>

                  <Route path="/ci_compute">
                    <Compute />
                  </Route>
                  
                  <Route path="/cia_code_analysis">
                    <CiaCodeAnalysis />
                  </Route>

                  <Route path="/area_manager">
                    <AreaManager />
                  </Route>


                  <Route path="/dashboard_check">
                    <DashboardCheck/> 
                  </Route>  

                  {/* <Route path="/pdf_upload">
                    <PDFUpload/>
                  </Route> */}

                  {/* <Route path ="/secrets">
                    <Secrets/>
                  </Route> */}
                  
                  <Route path="/deposit_form_submit">
                    <COD/>
                  </Route>

                  <Route path="/logs">
                    <Logs/>
                  </Route>

                  <Route path="/cod_posting_history">
                    <DepositHistory/>
                  </Route>

                  <Route path="/cod_form_approve">
                    <ConfirmationScreen/>
                  </Route>

                  <Route path="/enable_stations">
                    <SelectionScreen/>
                  </Route>

                  <Route path="/cod_report1">
                    <DocumentWiseComparison/>
                  </Route>

                  <Route path="/cod_report2">
                    <AgeingReport/>
                  </Route>

                  <Route path="/loss_and_recovery">
                    <LossAndRecovery/>
                  </Route>

                  <Route path="/lar_posted">
                    <RecoveryHistory/>
                  </Route>

                  <Route path="/stations_not_posted">
                    <StationNotPosted />
                  </Route>

                  <Route path="/cod_submission_status">
                    <CODSubmissionStatus/>
                  </Route>

                  <Route path="/remittance_history">
                    <RemittanceHistory/>
                  </Route>

                  <Route path='/portal_amreport'>
                    <AMReport/>
                  </Route>

                  <Route path='/portal_pmreport'>
                    <PMReport/>
                  </Route>

                  <Route path='/portal_excreport'>
                    <ExceptionReport/>
                  </Route>

                  <Route path='/portal_areamanagerreport'>
                    <AreaManagerReport/>
                  </Route>
                  
                  <Route path="/areamanager_dashboard">
                    <AreaManagerDashboard />
                  </Route>

                  <Route path='/branchmanager_history'>
                    <BranchManagerHistory/>
                  </Route>

                  <Route path='/areamanager_history'>
                    <AreaManagerHistory/>
                  </Route>

                  {/* <Route path="/beforejson">
                    <BeforeJSON/>
                  </Route> */}

                  <Route path="/extend_VM">
                    <ExtendVendorMaster/>
                  </Route>

                  <Route path="/extend_IM">
                    <ExtendItemMaster/>
                  </Route>

                  <Route path="/Dynamic_Forms">
                    <Dynamic_Forms/>
                  </Route>
                  
          

                  <Route path='/employee_cost_report'>
                    <EmployeeCostReport/>
                  </Route>

                  <Route path='/bulk_Approvals'>
                    <BulkApprovals/>
                  </Route>

                  <Route path="/bank-statement">
                    <BankStatement/>
                  </Route>

                  <Route path="/ledger">
                    <LedgerView/>
                  </Route>

                  <Route path="/Approval">
                    <Edureka_Approval/>
                  </Route>

                  <Route path='/inbox'>
                    {/* <FetchEmail/> */}
                    <InboxInitialInput/>
                  </Route>

                  <Route path="/functionalprofitandloss">
                    <FunctionalProfitAndLoss/>
                  </Route>

                  <Route path="/AccountDetermination_Report">
                    <AccountDetermination_Report/>
                  </Route>

                  <Route path="/Amortize_Bill_Report">
                    <Amortize_Bill_Report/>
                  </Route>

                  <Route path='/expense_amortization_status_report'>
                    <ExpenseAmortizationReport/>
                  </Route>

                  <Route path='/provision_status_report'>
                    <ProvisionStatusReport/>
                  </Route>
                  
                  <Route path="/Cost_Allocation_Report">
                    <Cost_Allocation_Report/>
                  </Route>

                  <Route path='/expense_journal'>
                    <ExpenseJournalDump/>
                  </Route>

                  <Route path='/tally'>
                    <TallyInputUserSelect/>
                  </Route>

                  <Route path='/billAnalysisAndStatusReport'>
                    <BillAnalysisAndStatusReport/>
                  </Route>

                  <Route path="/periodcostsheet_report">
                    <PeriodCostSheetReport/>
                  </Route>

                  <Route path="/payment_screen">
                    <NewPaymentScreen/>
                  </Route>

                  <Route path='/instructor_cost_accrual_report'>
                    <InstructorCostAccrualReport/>
                  </Route>


                  <Route path = "/vendorListReport">
                    <VendorListReport/>
                  </Route>

                  <Route path = "/itemListReport">
                    <ItemListReport/>
                  </Route>

                  <Route path='/cost_allocation_contribution'>
                    <CostContribution/>
                  </Route>

                  <Route path='/cost_allocation_contribution_v2'>
                    <CostContributionV2/>
                  </Route>

                  <Route path="/BookClosure">
                    <BookClosure/>
                  </Route>

                  <Route path='/reports_agri'>
                    <NewFunctionalForFrance/>
                  </Route>

                  <Route path='/employeeCostSplitup'>
                    <EmployeeCostSplitup/>
                  </Route>

                  <Route path='/functional_p_l_journal'>
                    <FunctionalJournal/>
                  </Route>

                  <Route path='/period_end_process'>
                    <Period_end_process/>
                  </Route>

                  <Route path='/schemaNew'>
                    <SchemaNew/>
                  </Route>

                  {/* <Route path="/Pivot_Table">
                    <Pivot_Table/>
                  </Route> */}
      
                  <Route path="/pdm">
                    <PDM />
                  </Route>

                  <Route path="/textract">
                    <Textract/>
                  </Route>
      
      
                  <Route path="/menu_tree">
                    <MenuTree />
                  </Route>

                  <Route path="/tds_recon">
                    <TDSRecon />
                  </Route>
      

                  <Route path="/claculator">
                    <Calculator />
                  </Route>
      

                  <Route path="/calculator">
                    <Calculator />
                  </Route>
      

                  <Route path="/event_history">
                    <EventHistory />
                  </Route>

                  <Route path="/event_capture">
                    <EventCapture />
                  </Route>

                  <Route path="/event_sequence">
                    <SeqOfEvents />
                  </Route>

                  <Route path="/decp">
                    <DECP />
                  </Route>

                  <Route path="/predictions">
                    <Predictions />
                  </Route>

                  <Route path="/get_predictions">
                    <GetPredictions />
                  </Route>

                  <Route path="/evaluation_predictions">
                    <EvaluationPredictions />
                  </Route>

                  <Route path="/recomm_history">
                    <RecommHistory />
                  </Route>
      

                  <Route path="/execute_sri">
                    <SRIRun />
                  </Route>
      
      

                  <Route path="/recon_status">
                    <ReconStatus />
                  </Route>
      
                  

                  <Route path="/run_recommendations">
                    <RecomRun />
                  </Route>

                  <Route path="/reverse_recommendations">
                    <ReverseRecom />
                  </Route>
      
                  

                  <Route path="/process_recommendations">
                    <ProcessRecom />
                  </Route>
      
                  

                  <Route path="/io_recon">
                    <InputOutputRecon />
                  </Route>
      

                  <Route path="/cross_tan_recommendations">
                    <CrossTANRecomm />
                  </Route>

                  <Route path="/control_total">
                    <ControlTotal />
                  </Route>  

                  <Route path="/ReconciliationDetailReport">
                    <ReconciliationDetailReport />
                  </Route>  

                  <Route path="/ReconciliationSummary">
                    <ReconciliationSummary />
                  </Route> 

                  <Route path="/tan_to_customer_mapping">
                    <TANtoCustomerMapping />
                  </Route>      
                  
                  <Route path="/tds_recon_sheet">
                    <TDSReconSheet />
                  </Route>

                  <Route path="/reconciliation">
                    <Reconciliation />
                  </Route>

                  <Route path="/26AS_dashboard">
                    <TDSDashboard />
                  </Route>      
                  

                  <Route path="/consolidated_dashboard">
                    <ConsolidatedDashboard />
                  </Route>

                  <Route path = "/deferredRevenueAndAmortization">
                    <DeferredRevenueAndAmortization/>
                  </Route>

                  <Route path='/salesReport'>
                    <SalesReport/>
                  </Route>
      
                  
      
                  <Route path="/po_dashboard">
                    <PoDashboard />
                  </Route>
      
                  
      
                  <Route path="/visual_rendering">
                    <VisualRender />
                  </Route>
      
                  
                  
      
            
      
                  
                  <Route path="/sch_summary_report">
                    {/* <Sidebar1 pageTitle="Schedule Summary Report" component={ <SchSummaryReport />}/> */}
                    <SchSummaryReport />
                  </Route>
      
                  
                  <Route path="/inception_transition_report">
                    {/* <Sidebar1 pageTitle="Inception / Transition Postings" component={ <AccountReports type="LAS01A_Lease_Accounting_Inception_Transition" />}/> */}
                    <AccountReports type="LAS01A_Lease_Accounting_Inception_Transition" />
                  </Route>
      
                  
                  
                  <Route path="/depreciation_report">
                    {/* <Sidebar1 pageTitle="Depreciation Postings" component={ <AccountReports type="LAS02A_Lease_Accounting_Depreciation" />}/
                    > */}
                    <AccountReports type="LAS02A_Lease_Accounting_Depreciation" />
                  </Route>
      
                  
                  <Route path="/cios_attendance_and_productivity_analysis">
                    {/* <Sidebar1 pageTitle="Depreciation Postings" component={ <AccountReports type="LAS02A_Lease_Accounting_Depreciation" />}/
                    > */}
                    <AttendanceProdReport dashboard_id="58f2694e-8760-4f38-b54a-2a3727827c7c" />
                  </Route>
                  
                  
                  <Route key=":dashboard_id" exact path="/dashboard/:dashboard_id" component={Dashboard} />
                    {/* <Sidebar1 pageTitle="Depreciation Postings" component={ <AccountReports type="LAS02A_Lease_Accounting_Depreciation" />}/
                    > */}
                    {/* <Dashboard /> */}
                  {/* </Route> */}
                  
                  <Route path="/client_data_analysis">
                    {/* <Sidebar1 pageTitle="Depreciation Postings" component={ <AccountReports type="LAS02A_Lease_Accounting_Depreciation" />}/
                    > */}
                    <ClientDataAnalysisReport dashboard_id="e98a6c1c-0341-41c2-a7e9-4fd0e03cd434" />
                  </Route>
                  
                  <Route path="/client_data_reconciliation">
                    <ClientDataReconcileReport dashboard_id="a4bfa96d-d5c3-4ef7-a4a0-ff3bea3527e5" />
                  </Route>
                  
                  <Route path="/associate_advance_analysis">
                    <AssociateAdvanceAnalysis dashboard_id="15a750c5-9e12-4b7b-9c9e-4334cda6fd31" />
                  </Route>
      
                  
                  
                  <Route path="/cost_liability_report">
                    {/* <Sidebar1 pageTitle=" Interest Postings" component={ <AccountReports type="LAS03A_Lease_Accounting_Interest" />}/> */}
                    <AccountReports type="LAS03A_Lease_Accounting_Interest" />
                  </Route>
      
                  
                  
                  <Route path="/payments_report">
                    {/* <Sidebar1 pageTitle=" Lease Liability Reduced Postings" component={ <AccountReports type="LAS04A_Lease_Accounting_Reduce_Liability" />}/> */}
                    <AccountReports type="LAS04A_Lease_Accounting_Reduce_Liability" />
                  </Route>
                  
                  
                  <Route path="/rent_posting_report">
                    {/* <Sidebar1 pageTitle="Rent Postings" component={ <AccountReports type="LAS05A_Lease_Accounting_Rent_Posting" />}/> */}
                    <AccountReports type="LAS05A_Lease_Accounting_Rent_Posting" />
                  </Route>
      
                  
                  
                  
                  <Route path="/modification_setup_report">
                    {/* <Sidebar1 pageTitle="Mod. Setup Postings" component={ <AccountReports type="LAS06A_Lease_Accounting_Modification_Setup" />}/> */}
                    <AccountReports type="LAS06A_Lease_Accounting_Modification_Setup" />
                  </Route>
      
                  
                  
                  
                  <Route path="/decrese_in_scope_report">
                    {/* <Sidebar1 pageTitle="Decrease in Scope Postings" component={ <AccountReports type="LAS07A_Lease_Accounting_Decrease_in_Scope" />}/> */}
                    <AccountReports type="LAS07A_Lease_Accounting_Decrease_in_Scope" />
                  </Route>
      
                  
                  
                  
                  <Route path="/nodecrese_in_scope_report">
                    {/* <Sidebar1 pageTitle="No Decrease in Scope Postings" component={ <AccountReports type="LAS08A_Lease_Accounting_NoDecrease_in_Scope" />}/> */}
                    <AccountReports type="LAS08A_Lease_Accounting_NoDecrease_in_Scope" />
                  </Route>
      
                  
                  
                  <Route path="/sch_sumary_details">
                    {/* <Sidebar1 pageTitle="Schedule Detail Report" component={ <SchSummaryDetails />}/> */}
                    <SchSummaryDetails />
                  </Route>
      
                  
      
                  <Route path="/transactions">
                    {/* <Sidebar1 pageTitle="Load Status" component={ <Transactions />}/> */}
                    <Transactions />
                  </Route>
      
      
                  <Route path="/upload">
                      <Upload />
                  </Route>
      
                  <Route path="/uploadv2">                  
                     <Upload2 />
                  </Route>

                  <Route path="/uploadv3">                  
                     <Upload3 />
                  </Route>

                  <Route path="/uploadv4">                  
                     <Upload4 />
                  </Route>

                  <Route path="/creditCardUpload">                  
                     <CreditCardUpload />
                  </Route>


                  <Route path="/etds">
                    <Etds />
                  </Route>  

                  <Route path="/schema">
                    <Schema />
                  </Route>      
      
      
                  <Route exact path="/rt">
                    <RevenueTariff />
                  </Route>
      
                  <Route exact path="/rts">
                    <RevenueTariffSlab />
                  </Route>
      
      
                  <Route path="/sch_generator">
                    <SchGenerator />
                  </Route>
      
      
      
                  <Route path="/fixed_asset_register">
                    <FixedAssetRegister />
                  </Route>
      
      
                  <Route path="/fixed_asset_deletion">
                    <FixedAssetDeletion />
                  </Route>
      
                  <Route path="/generate_components">
                    <GenerateComponent />
                  </Route>
      
                  <Route path="/demand_planning">
                    <DemandPlanning />
                  </Route>
      
                  <Route path="/generate_section">
                    <GenerateSection />
                  </Route>
      
                  <Route path="/new_design">
                    <NewDesign />
                  </Route>
      
      
                  <Route exact path="/404">
                    <Nopage />
                  </Route>

                  <Route exact path="/Edureka_OTP_Approval/:token">
                    <Edureka_OTP_Approval />
                  </Route>
      
                  <Redirect to="/" />
                  
                </Switch> 
                {/* <Chatbot key={11} /> */}
                </> :
              <Switch>

                
                <Route exact path="/">
                  <Login />
                </Route>
                
                <Route exact path="/signup">
                  <Login />
                </Route>    

                <Route exact path="/signup/terms">
                  <Login />
                </Route>   
                 
                <Route exact path="/signup/privacy">
                  <Login />
                </Route>    
    
                <Route exact path="/login">
                  <Login />
                </Route>
    
    
                <Route exact path="/Edureka_OTP_Approval/:token">
                  <Edureka_OTP_Approval />
                </Route>

                <Route exact path="/confirmation/:token">
                  <Confirmation />
                </Route>
    
    
                <Route exact path="/changePass/:token">
                  <ResetPassword />
                </Route>

                <Redirect to="/" />
    
              </Switch> }
          
          {
          this.props.store.common.url !== null 
          && 
          !window.location.href.includes('Edureka_OTP_Approval') 
          &&
          !window.location.href.includes('changePass')
          &&
          !window.location.href.includes('confirmation')
          ? 
            <Redirect to={this.props.store.common.url } />
            : null
          }

        </Router>
        <ToastContainer />
        
        <div className={ this.props.store.login.user.login ? "alert-box-with-app-bar" : "alert-box" } >
          { this.props.store.common.alert ?
            // <Alert severity={this.props.store.common.status}>{ this.props.store.common.alert }</Alert>
            <div className={ "alert alert-" + this.props.store.common.status }><strong>{ this.props.store.common.alert } </strong></div>
             : 
             null
           }
        </div>
        {this.props.store.common.loading ? (
          <div className="application-loader">
            <div className="center">
              <div className="wave"></div>
              <div className="wave"></div>
              <div className="wave"></div>
              <div className="wave"></div>
              <div className="wave"></div>
              <div className="wave"></div>
              <div className="wave"></div>
              <div className="wave"></div>
              <div className="wave"></div>
              <div className="wave"></div>
            </div>
          </div>
        ) : null}
        </div>
        );
    }
  }



const mapStateToProps = (state) => {
  // console.log(state);
  return {
    store : state
  }
};


export default connect(
  mapStateToProps
)(App);