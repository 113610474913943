import React, { Fragment, useEffect, useState } from 'react';
import { getRequest, postRequest } from '../../globalhelper/helper';
import FormBasicInput from '../CI/FormBasicInput';
import '../CI/LARPopup.css';
import FetchEmail from './FetchEmail';

const InboxInitialInput = () => {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [mainScreen, showMainScreen] = useState(false);
  const [resultArray, setResultArray] = useState([]);
  const [message, setMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [company, setCompany] = useState('');
  const [companiesAssigned, setCompaniesAssigned] = useState([]);
  const [companyName, setCompanyName] = useState([]);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [orgid, setOrgid] = useState('');

  const handleFromChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToChange = (e) => {
    setToDate(e.target.value);
  };

  const handleChange = (e) => {
    setCompany(e.target.value);
  };

  const getCurrentDateAndFirstDateOfMonth = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Add 1 because months are 0-based
    const firstDateOfMonth = `${year}-${month}-01`;

    const currentDate = today.toISOString().split('T')[0];

    return { currentDate, firstDateOfMonth };
  };

  useEffect(() => {
    const { currentDate, firstDateOfMonth } = getCurrentDateAndFirstDateOfMonth();
    setFromDate(firstDateOfMonth);
    setToDate(currentDate);
  }, []);

  useEffect(() => {
    getRequest(`api/edureka/getCompanyAssignedToUser`)
      .then((response) => {
        console.log(response.res.data);
        const responseData = response.res.data;
        const orgname = responseData.map((item) => item.orgname);
        // const convertedValue = arr.map(item => `'${item}'`)
        // const orgid = `(${convertedValue.join(', ')})`
        // console.log(orgid)
        setCompanyDetails(responseData);
        setCompanyName(orgname);
        if (orgname.length === 1) {
          setCompany(orgname[0]);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const getDateList = (startDate, endDate) => {
    const dateList = [];

    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      const dateString = currentDate.toISOString().split('T')[0];
      dateList.push(dateString);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateList;
  };

  const getDateListOldFormat = (startDate, endDate) => {
    const dates = [];
    let currentDate = startDate;

    while (currentDate <= endDate) {
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1; // Months are 0-based
      const day = currentDate.getDate();

      const formattedDate = `${year}-${month}-${day}`;
      dates.push(formattedDate);

      // Increment currentDate for the next iteration
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  };

  const formatDates = (dateArray) => {
    const formattedDates = [];

    dateArray.forEach((inputDate) => {
      const dateParts = inputDate.split('-');
      const year = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10) - 1;
      const day = parseInt(dateParts[2], 10);

      const date = new Date(year, month, day);

      const fday = String(date.getDate()).padStart(2, '0');
      const fmonth = String(date.getMonth() + 1).padStart(2, '0');
      const fyear = date.getFullYear();

      const formatDate = `${fday}-${fmonth}-${fyear}`;

      formattedDates.push(formatDate);
    });

    return formattedDates;
  };

  const formatDatesOldFormat = (dateArray) => {
    const formattedDates = [];

    dateArray.forEach((inputDate) => {
      const dateParts = inputDate.split('-');
      const year = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10) - 1;
      const day = parseInt(dateParts[2], 10);

      const date = new Date(year, month, day);

      const fday = String(date.getDate());
      const fmonth = String(date.getMonth() + 1);
      const fyear = date.getFullYear();

      const formatDate = `${fday}-${fmonth}-${fyear}`;

      formattedDates.push(formatDate);
    });

    return formattedDates;
  };

  const handleSubmit = () => {
    const dateList = getDateList(new Date(fromDate), new Date(toDate));

    const dateListOld = getDateListOldFormat(new Date(fromDate), new Date(toDate));

    console.log(dateList);

    const formatDate = formatDates(dateList);

    const formatDateOld = formatDatesOldFormat(dateListOld);

    const finalFormat = formatDate.concat(formatDateOld);

    console.log(finalFormat);

    const id = companyDetails.filter((item) => item.orgname === company);

    console.log(id);

    const companyid = id.map((item) => item.orgid);

    console.log(companyid);

    if (finalFormat && company) {
      postRequest(`api/edureka/inboxFilter`, { item: finalFormat, companies: companyid[0] })
        .then((response) => {
          console.log(response.res.data);
          if (response.res.data.length) {
            setOrgid(companyid[0]);
            setResultArray(response.res.data);
            showMainScreen(true);
          } else {
            setMessage(true);
            setErrorMessage('No Records to display');
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setMessage(true);
      setErrorMessage('Please select all the required fields');
    }
  };

  if (mainScreen) {
    return (
      <FetchEmail
        value={resultArray}
        company={orgid}
        companyName={company}
      />
    );
  } else
    return (
      <Fragment>
        <div
          style={{
            alignItems: 'left',
            fontSize: '1rem',
            fontWeight: 'bolder',
            marginBottom: '1rem',
            marginTop: '1rem'
          }}>
          Please select the date range to view Inbox
        </div>

        <div style={{ marginBottom: '1rem' }}>
          <div className="outline">
            <div className="col-lg-2 content">Company:</div>

            {companyName.length === 1 ? (
              // If there's only one company, display it as a read-only input
              <input
                type="text"
                className="col-lg-3 input_content"
                value={companyName[0]}
                name="company"
                readOnly
              />
            ) : (
              // If there are multiple companies, show the dropdown
              <select
                className="col-lg-3 input_content"
                value={company}
                name="company"
                onChange={handleChange}>
                <option
                  value=""
                  disabled>
                  Select
                </option>
                {companyName.sort().map((item, index) => (
                  <option
                    key={index}
                    value={item}>
                    {item}
                  </option>
                ))}
              </select>
            )}
          </div>

          <FormBasicInput
            label="From Date"
            name="fromDate"
            value={fromDate}
            type="date"
            min="2023-01-01"
            disabled={false}
            onChange={handleFromChange}
          />

          <FormBasicInput
            label="To Date"
            name="toDate"
            value={toDate}
            type="date"
            disabled={false}
            onChange={handleToChange}
          />
        </div>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button
            className="btn green_button"
            onClick={handleSubmit}>
            Submit
          </button>
        </div>

        {message && <div className="center-screen">{errorMessage}</div>}
      </Fragment>
    );
};

export default InboxInitialInput;
