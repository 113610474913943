import { Brush } from "@material-ui/icons";
import {
  Autocomplete,
  Button,
  Grid,
  TextField
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  useGridApiContext
} from "@mui/x-data-grid";
import { useState } from "react";

export default function BulkEditCostAllocation({
  availableCAMs,
  onUpdate,
  visibleRows
}) {
  const apiRef = useGridApiContext();
  const [costAllocation, setCostAllocation] = useState(null);
  const [open, setOpen] = useState(false);
  const [invalid, setInvalid] = useState(true);

  const handleEdit = () => {
    setOpen(true);
  };

  const handleSubmit = () => {
    setOpen(false);
    const updatedRows = []
    for (const row of visibleRows) {
      updatedRows.push({ ...row, costallocationmethod: costAllocation });
    }
    onUpdate(updatedRows);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleChange = (value) => {
    setInvalid(!value);
    setCostAllocation(value);
  };

  return (
    <>
      <Button
        size="small"
        startIcon={<Brush />}
        onClick={handleEdit}
      >
        Bulk Edit
      </Button>
      <Dialog fullWidth maxWidth="xs" open={open}>
        <DialogTitle>Edit Cost Allocation</DialogTitle>
        <DialogContent dividers>
          <form>
            <Grid container spacing={2} sx={{ marginTop: 0.1 }}>
              <Grid item xs={12}>
                <Autocomplete
                  size="small"
                  label="Cost Allocation Method"
                  value={costAllocation}
                  onChange={(event, value) =>
                    handleChange(value)
                  }
                  options={availableCAMs}
                  renderInput={(params) => (
                    <TextField label="Cost Allocation Method" {...params} fullWidth />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} disabled={invalid}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
