import { Autocomplete, Button, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { upperFirst } from "lodash";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
const CostContributionFilter = ({
  costAllocations,
  setFilteredCostAllocations,
  clearFilterData,
  clearFilterInputs,
}) => {
  const [availableCAMs, setAvailableCAMs] = useState([]);
  const [availableParties, setAvailableParties] = useState([]);
  const [availableLedgerNames, setAvailableLedgerNames] = useState([]);
  const [availableTransactionTypes, setAvailableTransactionTypes] = useState(
    []
  );
  const [availableDocumentNumbers, setAvailableDocumentNumbers] = useState([]);
  const [filters, setFilters] = useState({
    partyName: null,
    costAllocationMethod: null,
    ledgerName: null,
    documentNumber: null,
    transactionType: null,
  });
  useEffect(() => {
    clearFilterInputs.current = () => {
      setFilters({
        partyName: null,
        costAllocationMethod: null,
        ledgerName: null,
        documentNumber: null,
        transactionType: null,
      });
    };
    clearFilterData.current = () => {
      setAvailableCAMs([]);
      setAvailableParties([]);
      setAvailableLedgerNames([]);
      setAvailableTransactionTypes([]);
    };
  });

  const onFilterChange = (field, value) => {
    setFilters((filters) => {
      const modifiedFilters = { ...filters };
      modifiedFilters[field] = value;
      return modifiedFilters;
    });
  };

  const clearFilter = () => {
    setFilters(() => ({
      partyName: null,
      costAllocationMethod: null,
      ledgerName: null,
      transactionType: null,
    }));
  };

  useEffect(() => {
    setFilteredCostAllocations(() => {
      const availableLedgerNames = new Set();
      const availableParties = new Set();
      const availableTransactionTypes = new Set();
      const availableCostAllocationsMethods = new Set();
      const availableDocumentNos = new Set();
      const filtered = costAllocations.filter((costAllocation) => {
        if (filters.partyName) {
          if (!`${costAllocation.sendername}`.toLowerCase().includes(filters.partyName.toLowerCase())) {
            return false;
          }
        }
        if (filters.ledgerName) {
          if (!`${costAllocation.ledgername}`.toLowerCase().includes(filters.ledgerName.toLowerCase())) {
            return false;
          }
        }
        if (filters.transactionType) {
          if (
            !`${costAllocation.refTransactionType}`.toLowerCase().includes(
              filters.transactionType.toLowerCase()
            )
          ) {
            return false;
          }
        }
        if (filters.documentNumber) {
          if (!`${costAllocation.createdid}`.toLowerCase().includes(filters.documentNumber.toLowerCase())) {
            return false;
          }
        }

        if (filters.costAllocationMethod) {
          if (`${filters.costAllocationMethod}`.toLowerCase() === "blank") {            
            if (costAllocation.costallocationmethod) {
              return false;
            }
          } else {
            if (
              !`${costAllocation.costallocationmethod}`.toLowerCase().includes(
                `${filters.costAllocationMethod}`.toLowerCase()
              )
            ) {
              return false;
            }
          }
        }
        if (costAllocation.costallocationmethod)
          availableCostAllocationsMethods.add(
            upperFirst(costAllocation.costallocationmethod)
          );
        if (costAllocation.ledgername)
          availableLedgerNames.add(upperFirst(costAllocation.ledgername));
        if (costAllocation.sendername)
          availableParties.add(upperFirst(costAllocation.sendername.toLowerCase()));
        if (costAllocation.refTransactionType)
          availableTransactionTypes.add(upperFirst(costAllocation.refTransactionType));
        if (costAllocation.createdid)
          availableDocumentNos.add(upperFirst(costAllocation.createdid));
        return true;
      });
      const availableCAMS = Array.from(availableCostAllocationsMethods).sort();
      availableCAMS.unshift("Blank");
      setAvailableCAMs(availableCAMS);
      setAvailableLedgerNames(Array.from(availableLedgerNames.values()).sort());
      setAvailableParties(Array.from(availableParties.values()).sort());
      setAvailableTransactionTypes(
        Array.from(availableTransactionTypes).sort()
      );
      setAvailableDocumentNumbers(Array.from(availableDocumentNos).sort());
      return filtered;
    });
  }, [filters, costAllocations, setFilteredCostAllocations]);

  return (
    <>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        Filter
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Autocomplete
            size="small"
            label="Cost Allocation Method"
            value={filters["costAllocationMethod"]}
            onChange={(event, value) =>
              onFilterChange("costAllocationMethod", value)
            }
            options={availableCAMs}
            renderInput={(params) => (
              <TextField label="Cost Allocation Method" {...params} />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            size="small"
            label="Expense Ledger"
            value={filters["ledgerName"]}
            onChange={(event, value) => onFilterChange("ledgerName", value)}
            options={availableLedgerNames}
            renderInput={(params) => (
              <TextField label="Expense Ledger" {...params} />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            size="small"
            label="Vendor/Party Name"
            value={filters["partyName"]}
            onChange={(event, value) => onFilterChange("partyName", value)}
            options={availableParties}
            renderInput={(params) => (
              <TextField label="Vendor/Party Name" {...params} />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            size="small"
            label="Transaction Types"
            value={filters["transactionType"]}
            onChange={(event, value) =>
              onFilterChange("transactionType", value)
            }
            options={availableTransactionTypes}
            renderInput={(params) => (
              <TextField label="Transaction Types" {...params} />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            size="small"
            label="Document Number"
            value={filters["documentNumber"]}
            onChange={(event, value) => onFilterChange("documentNumber", value)}
            options={availableDocumentNumbers}
            renderInput={(params) => (
              <TextField label="Document Number" {...params} />
            )}
          />
        </Grid>
        <Grid item xs={4} display="flex" gap={2}>
          <Button variant="outlined" onClick={clearFilter}>
            Clear Filter
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

function mapStateToProps(state) {
  return {
    state,
  };
}

export default connect(mapStateToProps)(CostContributionFilter);
