import React, { Component, Fragment } from 'react';
import axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  handleTreeChange,
  getDropdownOptions,
  updateChangeLogs,
  updateStoreVals,
  updateAthenaStorage,
  changeDependencyValues,
  changeValByserviceID,
  update_one_side_PDF_Key,
  updateDynamicOption_Done,
  Set_ORG_ID_CONST
} from '../../actions/vr2Action';
import _ from 'lodash';
import data from './sample.json';
import Spinner from '../../components/BackDrop_Spinner';

import { getRequest, postRequest } from '../../globalhelper/helper';
import Popup from '../../components/SimplePOPUP';

import { Table } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import { ImFolderUpload } from 'react-icons/im';
// import Tooltip from '../../components/GlobalComponents/Tooltip';

import { Tooltip } from '@mui/material';

import Select from 'react-select';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

//mbp
import ProgressComponent from '../../components/progressComponent';
// import NewTabComponent from './newTabComponent';
import ReactDOM from 'react-dom';
export class form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      logs: {},
      showPopUP_PDF: false,
      popData_PDF: false,
      showSpinner: false,
      dynamicOption: [],
      popup_select: [],
      open_popup_select: false,
      popup_table: false,
      Give_Val_By_ServiceKey: {},

      //mbp
      selectedVendorName: '',
      selectedLedgerName: '',
      similarity12: 0,
      similarity13: 0,
      similarity23: 0,
      showSimilarityPopup: false,
      similarityresult12: 0, // display in screen
      // modal
      showModal: false,

      billvendorname: localStorage.getItem('billvendorname'),
      vendorNameAsPerMaster: localStorage.getItem('vendornameAsperMaster')
        ? localStorage.getItem('vendornameAsperMaster')
        : 'no value',
      vendorLedgerName: localStorage.getItem('vendorledgerName'),

      ROW: null,

      screenInfo: {
        screenName: 'VendorMaster',
        screenAction: 'create',
        nav_to: 'VR2_Forms'
      }

      //---
    };

    this.token = this.props.screenName;
  }

  componentDidMount() {
    this.TaxCategory();
    //mbp
    this.storeInputSearchValuesInLocalStorage();

    //--
    setTimeout(() => {
      if (this.token === 'BillExtract' && this.props.tree.title === 'Vendor Account Info') {
        this.autoSelectVendorNamePopup();
      }
    }, 2000);
    //mbp
    this.initialhandleCheckSimilarity(this.state.billvendorname, this.state.vendorNameAsPerMaster);
  }

  //mbp
  storeInputSearchValuesInLocalStorage = () => {
    this.props.tree.children.forEach((treenode) => {
      if (treenode.type === 'section' && treenode.view === 'form') {
        treenode.children.forEach((childNode) => {
          if (childNode.type === 'input_search' && childNode.val) {
            localStorage.setItem('billvendorname', childNode.val);
          } else if (childNode.type === 'input_vendormasternamestatus') {
            localStorage.setItem('vendornameAsperMaster', childNode.val);
          } else if (childNode.type === 'input_ledgernamestatus') {
            localStorage.setItem('vendorledgerName', childNode.val);
          }
        });
      }
    });
  };

  initialhandleCheckSimilarity = (name1, name2) => {
    console.log('inputnames', name1, name2);
    const similarityresult12 = this.calculateHighestSimilarity(name1, name2);

    this.setState({ similarityresult12: Math.ceil(similarityresult12) });
  };
  // ---

  componentWillReceiveProps(nextProps) {
    if (this.props.time !== nextProps.time) {
      // Perform any logic you need to update your component state based on the new props
      this.setState({
        // Update the component's state as needed
        arvind: 'python master'
      });
    }
  }

  myChangeHandler = (event, childrenNode, date) => {
    let serviceKey = event.target.name;
    let serviceID = event.target.id;
    let val = event.target.value;

    if (date) {
      let date = moment(val, 'YYYY-MM-DD').format('DD-MM-YYYY');

      val = date === 'Invalid date' ? val : date;
    }

    console.log(serviceKey, serviceID, val, childrenNode, date);

    console.log(val);

    let DateTime = moment(new Date()).format('DD-MM-YYYY HH:mm:ss');
    console.log(DateTime);

    let logs = this.props.changeLogs;
    let vals = this.props.storeVals;

    logs[serviceID] = {
      serviceKey: serviceKey,
      serviceID: serviceID,
      value: val,
      DateTime,
      user: this.props.userData.USR_Email
    };

    vals[serviceID] = val;

    if (childrenNode.changeAll && childrenNode.changeAll === 'true') {
      this.changeValByserviceID(childrenNode.serviceKey, 'serviceKey', 'val', val, 'changeAll');
    } else {
      console.log(childrenNode.changeAll, childrenNode.changeAll === 'true');

      this.changeValByserviceID(serviceID, 'serviceID', 'val', val);
    }

    this.props.updateChangeLogs(logs);
    this.props.updateStoreVals(vals);

    // this.setState({ [serviceKey]: val, logs });
    this.props.rerender('noQuit');
  };

  TaxCategory = () => {
    console.log('TaxCategory changing');

    let OrganizationPlaceofSupply;
    let VendorSourceofSupply;

    let VendorGSTNumber;
    let GSTNumber;

    let VR2_Form_Json = this.props.VR2_Form_Json;

    let found = false;

    VR2_Form_Json.forEach((each, i) => {
      if (found) return;
      if (each.children && each.children.length) {
        each.children.forEach((each2, j) => {
          if (found) return;
          if (each2.children && each2.children.length) {
            each2.children.forEach((each3, k) => {
              if (found) return;

              if (
                !each3.children &&
                each3.render &&
                each3.render === 'true' &&
                (each3.serviceKey === 'Organization Place of Supply' ||
                  each3.serviceKey === 'Vendor Source of Supply' ||
                  each3.serviceKey === 'Vendor GST Number' ||
                  each3.serviceKey === 'GST Number')
              ) {
                if (each3.serviceKey === 'Organization Place of Supply') {
                  OrganizationPlaceofSupply = each3.val;

                  console.log(each3);

                  if (OrganizationPlaceofSupply && VendorSourceofSupply && VendorGSTNumber && GSTNumber) found = true;
                } else if (each3.serviceKey === 'Vendor Source of Supply') {
                  VendorSourceofSupply = each3.val;

                  console.log(each3);

                  if (OrganizationPlaceofSupply && VendorSourceofSupply && VendorGSTNumber && GSTNumber) found = true;
                } else if (each3.serviceKey === 'Vendor GST Number') {
                  VendorGSTNumber = each3.val;

                  console.log(each3);

                  if (OrganizationPlaceofSupply && VendorSourceofSupply && VendorGSTNumber && GSTNumber) found = true;
                } else if (each3.serviceKey === 'GST Number') {
                  GSTNumber = each3.val;

                  console.log(each3);

                  if (OrganizationPlaceofSupply && VendorSourceofSupply && VendorGSTNumber && GSTNumber) found = true;
                }

                if (found) return;
              }
            });
          }
        });
      }
    });

    console.log(OrganizationPlaceofSupply, VendorSourceofSupply, VendorGSTNumber, GSTNumber);

    if (OrganizationPlaceofSupply !== undefined && VendorSourceofSupply !== undefined) {
      let val;

      if (
        OrganizationPlaceofSupply !== '' &&
        VendorSourceofSupply !== '' &&
        OrganizationPlaceofSupply !== '-' &&
        VendorSourceofSupply !== '-' &&
        OrganizationPlaceofSupply === VendorSourceofSupply
      ) {
        val = 'CGST/SGST';
      } else {
        val = 'IGST';
      }

      this.changeValByserviceID('Tax Category', 'serviceKey', 'val', val);
      this.props.rerender('noQuit');
    } else if (VendorGSTNumber !== undefined && GSTNumber !== undefined) {
      let val;

      if (
        VendorGSTNumber !== '' &&
        GSTNumber !== '' &&
        VendorGSTNumber !== '-' &&
        GSTNumber !== '-' &&
        VendorGSTNumber.substring(0, 2) === GSTNumber.substring(0, 2)
      ) {
        val = 'CGST/SGST';
      } else {
        val = 'IGST';
      }

      this.changeValByserviceID('Tax Category', 'serviceKey', 'val', val);
      this.props.rerender('noQuit');
    }
  };

  PO_IPNumber = (obj) => {
    if (obj.type === 'popup_select') {
      let Input = {
        PO_IPNumber: obj.val,
        screen: 'BillExtract'
      };

      postRequest('api/vr2apicall/PO_IPNumber', Input).then((res) => {
        if (res.res.status === 'success') {
          let DATA = JSON.parse(res.res.data);

          console.log(DATA);

          let athenaStorage = this.props.athenaStorage;

          athenaStorage['PO_IP_Item_Ref'] = DATA;

          this.props.updateAthenaStorage(athenaStorage);

          let newSet = [...new Set(DATA.map((each) => each['PO/IP Item Ref']))];

          let options = [];

          newSet.forEach((one) => {
            options.push({
              label: one,
              value: one
            });
          });

          this.changeValByserviceID('PO/IP Item Ref', 'serviceKey', 'options', options);
          this.props.rerender();
        }
      });
    } else if (obj.type === 'popup_select_goods') {
      let Input = {
        PO_IPNumber: obj.val,
        screen: 'Goods'
      };

      postRequest('api/vr2apicall/PO_IPNumber', Input).then((res) => {
        if (res.res.status === 'success') {
          let DATA = JSON.parse(res.res.data);

          console.log(DATA);

          let athenaStorage = this.props.athenaStorage;

          athenaStorage['ListofItemName'] = DATA;

          this.props.updateAthenaStorage(athenaStorage);

          let newSet = [...new Set(DATA.map((each) => each['Item Name']))];

          let options = [];

          newSet.forEach((one) => {
            options.push({
              label: one,
              value: one
            });
          });

          this.changeValByserviceID('Item Name', 'serviceKey', 'options', options);
          this.props.rerender();
        }
      });
    }
  };

  getOriginalGRN_SRN_No = async (DocumentType) => {
    await this.Give_Val_By_ServiceKey('Sender ID');
    let SenderID = this.state.Give_Val_By_ServiceKey['Sender ID'];

    getRequest(`api/vr2apicall/getOriginalGRN_SRN_No?SenderID=${SenderID}&DocumentType=${DocumentType}`).then((res) => {
      if (res.res.status === 'success') {
        let DATA = JSON.parse(res.res.data);

        console.log(DATA);

        let athenaStorage = this.props.athenaStorage;

        athenaStorage['Original GRN/SRN Number'] = DATA;

        this.props.updateAthenaStorage(athenaStorage);

        let newSet = [...new Set(DATA.map((each) => each['Original GRN/SRN Number']))];

        let options = [];

        newSet.forEach((one) => {
          options.push({
            label: one,
            value: one
          });
        });

        this.changeValByserviceID('Original GRN/SRN Number', 'serviceKey', 'options', options);
        this.props.rerender();
      }
    });
  };

  VendorIDonChange = () => {
    this.changeValByserviceID('Vendor ID', 'serviceKey', 'VendorIDonChange', []);

    this.props.onVendorIDonChange();
  };
  SenderIDonChange = () => {
    this.changeValByserviceID('Sender ID', 'serviceKey', 'SenderIDonChange', []);

    this.props.onVendorIDonChange();
  };
  BillExtractDoUpload = () => {
    this.changeValByserviceID('Upload (PDF)', 'serviceKey', 'editable', 'true');
  };

  TaxCategoryonChange = (val) => {
    this.changeValByserviceID('Tax Percentage', 'serviceKey', 'getTaxPercentage', val);
  };

  OrgIDConst = (val) => {
    this.props.Set_ORG_ID_CONST(val);
  };

  updateTaxCode = (TaxPercentage, TaxCategory, serviceID) => {
    console.log(TaxPercentage, TaxCategory, serviceID, 'ppppppppppppppppppppppppp');

    let SerID = serviceID.replace('taxpercentage', 'taxcode');

    let taxCode = TaxCategory + ' ' + String(Number(TaxPercentage)) + '%';

    this.changeValByserviceID(SerID, 'serviceID', 'val', taxCode);
  };

  changeValByserviceID = (serviceID_or_Key, matchBy, key, val, changeAll) => {
    console.log(serviceID_or_Key, val);

    let VR2_Form_Json = this.props.VR2_Form_Json;

    let found = false;

    VR2_Form_Json.forEach((each, i) => {
      if (found) return;
      if (each.children && each.children.length) {
        each.children.forEach((each2, j) => {
          if (found) return;
          if (each2.children && each2.children.length) {
            each2.children.forEach((each3, k) => {
              if (found) return;

              if (changeAll && this.trim(each3[matchBy]) === this.trim(serviceID_or_Key)) {
                each3[key] = val;
                this.props.handleTreeChange(VR2_Form_Json);
                console.log('1one');

                if (each3.serviceKey === 'Organization ID') this.OrgIDConst(val);
                if (each3.child_dependency) {
                  let dynamicOption_Done = this.props.dynamicOption_Done;

                  each3.child_dependency.forEach((per) => {
                    dynamicOption_Done = dynamicOption_Done.filter((item) => {
                      let splitArray = item.split('$');
                      return splitArray.every((part) => part !== per);
                    });
                  });

                  this.props.updateDynamicOption_Done(dynamicOption_Done);
                }
                return;
              } else if (
                !each3.children &&
                each3.render &&
                each3.render === 'true' &&
                this.trim(each3[matchBy]) === this.trim(serviceID_or_Key)
              ) {
                each3[key] = val;
                console.log('super1');
                console.log(each3);

                if (key === 'options' && each3.val && each3.onChange_operation) {
                  this.onChange_operation(each3, each3.val);
                }

                if (each3.serviceKey === 'PO/IP Type') {
                  this.props.toParent(val);
                }

                this.props.handleTreeChange(VR2_Form_Json);
                found = true;
                if (each3.serviceKey === 'Vendor ID' && key === 'val') this.VendorIDonChange();
                if (each3.serviceKey === 'Sender ID' && key === 'val') this.SenderIDonChange();
                if (this.token === 'BillExtract' && each3.serviceKey === 'Organization Name' && key === 'val')
                  this.BillExtractDoUpload();
                if (each3.serviceKey === 'DocumentType' && key === 'val' && this.token === 'GoodsAndServicesEntries')
                  this.getOriginalGRN_SRN_No(val);
                if (
                  each3.serviceKey === 'Organization Place of Supply' ||
                  each3.serviceKey === 'Vendor Source of Supply' ||
                  each3.serviceKey === 'Vendor GST Number' ||
                  each3.serviceKey === 'GST Number'
                )
                  this.TaxCategory();
                if (each3.serviceKey === 'Tax Category') this.TaxCategoryonChange(val);
                if (each3.serviceKey === 'PO/IP Number' || each3.serviceKey === 'Order Number') this.PO_IPNumber(each3);

                if (each3.serviceKey === 'Organization ID') this.OrgIDConst(val);
                if (each3.serviceKey === 'Receiver ID') this.OrgIDConst(val);

                if (each3.child_dependency) {
                  let dynamicOption_Done = this.props.dynamicOption_Done;

                  each3.child_dependency.forEach((per) => {
                    // let index = dynamicOption_Done.indexOf(per);
                    // if (index !== -1) {
                    //     dynamicOption_Done.splice(index, 1);
                    // }

                    dynamicOption_Done = dynamicOption_Done.filter((item) => {
                      let splitArray = item.split('$');
                      return splitArray.every((part) => part !== per);
                    });
                  });

                  this.props.updateDynamicOption_Done(dynamicOption_Done);
                }
                return;
              } else if (each3.children && each3.children.length) {
                each3.children.forEach((each4, m) => {
                  if (found) return;

                  if (changeAll && this.trim(each4[matchBy]) === this.trim(serviceID_or_Key)) {
                    each4[key] = val;
                    this.props.handleTreeChange(VR2_Form_Json);
                    console.log('2two');
                    return;
                  } else if (each4.render === 'true' && this.trim(each4[matchBy]) === this.trim(serviceID_or_Key)) {
                    if (each4.serviceKey === 'Tax Percentage' && key === 'getTaxPercentage') {
                      this.updateTaxCode(each4.val, val, each4.serviceID);
                    } else {
                      each4[key] = val;
                      console.log('super2');

                      this.props.handleTreeChange(VR2_Form_Json);

                      // if( each4.serviceKey === "PO/IP Item Ref" || each4.serviceKey === "Item Name") found = false
                      found = true;
                      return;
                    }
                  }
                });
              }
            });
          }
        });
      }
    });
  };

  option_undef = (childrenNode) => {
    console.log('if option_undef', childrenNode.title);

    let formArray_obj = {};

    // this.props.formArray.forEach( each => {
    //     formArray_obj[each.serviceKey] = each.val ? each.val : ""
    // })

    // getDropdownOptions(childrenNode.serviceID, this.props.formArray, formArray_obj, this.props.VR2_Form_Json, false)

    if (childrenNode.val) {
      let ev = {
        target: {
          id: childrenNode.serviceID,
          name: childrenNode.serviceKey,
          value: childrenNode.val
        }
      };

      this.onDropDownValueChange(ev, 'false', 'no_onBlur');
    }
  };

  onDropDownValueChange = (ev, valEmpty, no_onBlur) => {
    let serviceID = ev.target.id;
    let serviceKey = ev.target.name;
    let val = ev.target.value;

    //mbp
    console.log(val);

    this.changeValByserviceID(serviceID, 'serviceID', 'val', val);

    let DateTime = moment(new Date()).format('DD-MM-YYYY HH:mm:ss');
    console.log(DateTime);

    let logs = this.props.changeLogs;
    let vals = this.props.storeVals;

    logs[serviceID] = {
      serviceKey: serviceKey,
      serviceID: serviceID,
      value: val,
      DateTime,
      user: this.props.userData.USR_Email
    };
    vals[serviceID] = val;

    let formArray_obj = {};

    // this.props.formArray.forEach( each => {
    //     formArray_obj[each.serviceKey] = each.val ? each.val : ""
    // })

    // changeDependencyValues(serviceID, this.props.formArray, formArray_obj, this.props.VR2_Form_Json, valEmpty && valEmpty === "false" ? false : true );

    this.props.updateChangeLogs(logs);
    this.props.updateStoreVals(vals);

    this.props.rerender();
    this.setState(
      { [serviceKey]: val, logs },

      () =>
        no_onBlur
          ? null
          : this.onBlur({
              target: {
                id: serviceID,
                value: val
              }
            })
    );
  };
  if2hashRemoveLast = (myString) => {
    if (myString.includes('#') && (myString.match(/#/g) || []).length === 2) {
      // only containd 2 #

      let str = myString.split('#');

      str.splice(-1);

      return str.join('#');
    } else {
      return myString;
    }
  };

  onBlur = (e, childrenNode) => {
    let val = e.target.value;
    let id = e.target.id;

    // console.log(val)
    // console.log(id)

    if (val) {
      let API = _.filter(
        this.props.dependency_json,
        (item) => item.type === 'apiCall' && item.parentId === this.if2hashRemoveLast(id)
      );

      // console.log(API)

      if (API.length && API.length === 1) {
        let obj = {
          LOGS: this.props.changeLogs,
          TREE_JSON: this.props.VR2_Form_Json
        };

        console.log(obj);

        this.setState({ showSpinner: true }, () => {
          postRequest(`api/vr2/EditJson?module=${localStorage.getItem('module')}&screenName=${this.token}`, obj).then(
            (res) => {
              if (res.res.status === 'success') {
                console.log(res.res.data);

                this.props.handleTreeChange(res.res.data);

                if (this.token === 'BillExtract') {
                  this.putDefaultValue_BillExtract('DocumentType', 'Bill');
                  this.putDefaultValue_BillExtract('ExchangeRate', '1');
                }

                if (childrenNode && childrenNode.range_fn && childrenNode.range_fn === 'extract_pdf') {
                  this.changeValByserviceID(childrenNode.serviceID, 'serviceID', 'editable', 'false');
                  this.autoSelectVendorNamePopup();
                  this.props.rerender();
                }
              }

              this.setState({ showSpinner: false });
            }
          );
        });
      }
    }
  };

  autoSelectVendorNamePopup = () => {
    let VR2_Form_Json = this.props.VR2_Form_Json;
    let found = false;
    let goAhead;

    VR2_Form_Json.forEach((each, i) => {
      if (found) return;
      if (each.children && each.children.length) {
        each.children.forEach((each2, j) => {
          if (found) return;

          if (each2.title === 'Vendor Account Info' && each2.children && each2.children.length) {
            each2.children.forEach((each3, k) => {
              if (found) return;

              if (!each3.children && each3.render && each3.render === 'true' && each3.serviceKey === 'Vendor ID') {
                if (!each3.val) {
                  goAhead = true;
                }
              }
              if (
                !each3.children &&
                each3.render &&
                each3.render === 'true' &&
                each3.serviceKey === 'Vendor Name' &&
                each3.type === 'input_search' &&
                goAhead
              ) {
                this.input_select_fn(each3);

                found = true;
                return;
              }
            });
          }
        });
      }
    });
  };

  putDefaultValue_BillExtract = (KEY, VAL) => {
    console.log(KEY, VAL);

    let VR2_Form_Json = this.props.VR2_Form_Json;
    console.log(VR2_Form_Json);
    let found = false;

    VR2_Form_Json.forEach((each, i) => {
      if (found) return;
      if (each.children && each.children.length) {
        each.children.forEach((each2, j) => {
          if (found) return;
          if (each2.children && each2.children.length) {
            each2.children.forEach((each3, k) => {
              if (found) return;

              if (!each3.children && each3.render && each3.render === 'true' && each3.serviceKey === KEY) {
                if (!each3.val) {
                  each3.val = VAL;
                  this.props.handleTreeChange(VR2_Form_Json);
                }

                found = true;
                return;
              }
            });
          }
        });
      }
    });
  };

  date_DD_MM_YYYY_TO_YYYY_MM_DD = (json) => {
    let ok = JSON.parse(JSON.stringify(json));
    // console.log(ok.val)
    let date = moment(ok.val, 'DD-MM-YYYY').format('YYYY-MM-DD');
    // console.log(date)
    return date === 'Invalid date' ? ok.val : date;
  };

  current_date = (childrenNode) => {
    let date = moment(new Date()).format('DD-MM-YYYY');
    let ev = {
      target: {
        id: childrenNode.serviceID,
        name: childrenNode.serviceKey,
        value: date
      }
    };

    this.myChangeHandler(ev, childrenNode);
  };

  yesterday_date = (childrenNode) => {
    let date = moment(new Date()).subtract(1, 'day').format('DD-MM-YYYY');
    let ev = {
      target: {
        id: childrenNode.serviceID,
        name: childrenNode.serviceKey,
        value: date
      }
    };

    this.myChangeHandler(ev, childrenNode);
  };

  get_user_email = (childrenNode) => {
    let user = this.props.userData.USR_Email;

    let ev = {
      target: {
        id: childrenNode.serviceID,
        name: childrenNode.serviceKey,
        value: user
      }
    };

    this.myChangeHandler(ev, childrenNode);
  };

  generateUniqueNumber = () => {
    var timestamp = new Date().getTime();
    var uniqueNumber = timestamp.toString().substr(0, 10);

    console.log(uniqueNumber);
    return uniqueNumber;
  };

  removeSpecialCharacters_exceptDOT = (str) => {
    return str.replace(/[^\w\s.]/gi, ''); // only dot allowed
  };

  removeSpecialCharacters = (str) => {
    return str.replace(/[^\w\s]/gi, '');
  };

  onUpload = (e, childrenNode) => {
    let serviceKey = e.target.name;
    let serviceID = e.target.id;

    let DateTime = moment(new Date()).format('DD-MM-YYYY HH:mm:ss');
    console.log(DateTime);

    let logs = this.props.changeLogs;
    let vals = this.props.storeVals;

    let file = e.target.files[0];

    if (file && e.target.files && e.target.files.length) {
      let fileName = this.generateUniqueNumber() + this.removeSpecialCharacters_exceptDOT(file.name);

      console.log(fileName);

      const Module = localStorage.getItem('module');
      const screenName = this.removeSpecialCharacters(this.token);
      const fieldName = this.removeSpecialCharacters(serviceKey);

      let s3key = `UploadedFiles/${Module}/${screenName}/${fieldName}/${fileName}`;

      console.log(s3key);

      const formData = new FormData();
      formData.append('file', file);

      postRequest(
        `api/vr2/uploadPDF?module=${localStorage.getItem('module')}&screenName=${screenName}&fieldName=${fieldName}&fileName=${fileName}`,
        formData
      ).then((res) => {
        if (res.res.status === 'success') {
          this.changeValByserviceID(serviceID, 'serviceID', 'val', s3key);

          logs[serviceID] = {
            serviceKey: serviceKey,
            serviceID: serviceID,
            value: s3key,
            DateTime,
            user: this.props.userData.USR_Email
          };
          vals[serviceID] = s3key;

          this.props.updateChangeLogs(logs);
          this.props.updateStoreVals(vals);

          this.props.rerender();
          this.setState(
            {
              [serviceID]: file
            },
            () =>
              this.onBlur(
                {
                  target: {
                    id: serviceID,
                    value: file
                  }
                },
                childrenNode
              )
          );
        }
      });
    }
  };

  openPDF = (e, childrenNode) => {
    e.preventDefault();

    postRequest(`api/vr2/getPDF_file`, { key: childrenNode.val }).then((res) => {
      if (res.res.status === 'success') {
        if (childrenNode.subtype === 'upload_show') this.props.update_one_side_PDF_Key({ key: res.res.data, fileName: childrenNode.val });
        else
          this.setState({
            showPopUP_PDF: childrenNode.serviceID,
            popData_PDF: { data: res.res.data, fileName: childrenNode.val }
          });
      }
    });
  };

  trim = (str) => {
    return str.replace(/\s+/g, '').toLowerCase();
  };

  dynamic_option_fn = async (childrenNode) => {
    console.log('calling dynamic_option_fn =>' + childrenNode.serviceID);

    let hit = `${childrenNode.dynamic_option.api_Directory}?query_Name=${childrenNode.dynamic_option.query_Name}`;

    console.log('hitting', hit);

    let array;

    if (childrenNode.dynamic_option.dynamic_query) {
      array = [];

      await childrenNode.dynamic_option.dynamic_query.forEach(async (each) => {
        if (each.emailid) {
          let obj = {
            replaceStringWith: each.replaceStringWith,
            value: this.props.userData.USR_Email
          };
          array.push(obj);
        } else if (each.getValueFrom === 'Organization ID') {
          let obj = {
            replaceStringWith: each.replaceStringWith,
            value: this.props.ORG_ID_CONST
          };
          array.push(obj);
        } else {
          await this.Give_Val_By_ServiceKey(each.getValueFrom);
          let obj = {
            replaceStringWith: each.replaceStringWith,
            value: this.state.Give_Val_By_ServiceKey[each.getValueFrom]
          };
          array.push(obj);
        }
      });

      console.log(array, childrenNode.serviceID + 'llllllllllllllllllllllllllllll');
    }

    postRequest(hit, array ? { info: array } : null).then((res) => {
      if (res.res.status === 'success') {
        let DATA = res.res.data && res.res.data.length ? JSON.parse(res.res.data) : [];

        if (DATA.length) {
          // console.log(DATA.length)

          let matchKey = Object.keys(DATA[0]).find((each) => this.trim(each) === this.trim(childrenNode.serviceKey));

          console.log(matchKey, 'matchKey for', childrenNode.serviceID, DATA);

          if (matchKey) {
            if (childrenNode.dynamic_option.what_to_store) {
              let athenaStorage = this.props.athenaStorage;

              athenaStorage[childrenNode.dynamic_option.what_to_store] = DATA;

              this.props.updateAthenaStorage(athenaStorage);
            }

            let newSet = [...new Set(DATA.map((each) => each[matchKey]))];

            let options = newSet.map((one) => {
              return {
                label: one,
                value: one
              };
            });

            // console.log(options.length)

            this.changeValByserviceID(childrenNode.serviceID, 'serviceID', 'options', options);
            this.props.rerender();
          }
        }
      }
    });
  };

  removeSpecialChars = (str) => {
    return str.replace(/[^\w\s]/gi, '');
  };

  onChange_operation = (childrenNode, val) => {
    let ARRAY = this.props.athenaStorage[childrenNode.onChange_operation.get_From];

    let changeAll = childrenNode.onChange_operation.changeAll ? true : false;

    if (ARRAY && ARRAY[0]) {
      console.log(ARRAY[0]);

      let matchKey = Object.keys(ARRAY[0]).find((each) => this.trim(each) === this.trim(childrenNode.serviceKey));

      console.log(matchKey, 'matchKey');

      if (matchKey && childrenNode.type !== 'multiselect') {
        let LINES = ARRAY.filter((each) => each[matchKey] === val);

        console.log(LINES);

        if (
          childrenNode.onChange_operation.operation &&
          childrenNode.onChange_operation.operation === 'show_next_dropdown'
        ) {
          let child_Name = childrenNode.onChange_operation.child_Name;

          console.log(child_Name);

          if (typeof child_Name === 'object') {
            // child_Name will be some time list or a string
          } else {
            child_Name = [child_Name];
          }

          child_Name.forEach((item) => {
            let OPTIONS = [];

            if (LINES.length) {
              let newSet = [...new Set(LINES.map((each) => each[item]))];

              newSet.forEach((each) => {
                OPTIONS.push({
                  label: each,
                  value: each
                });
              });

              console.log(OPTIONS);

              this.changeValByserviceID(item, 'serviceKey', 'options', OPTIONS, changeAll);
              this.props.rerender();
            } else {
              this.changeValByserviceID(item, 'serviceKey', 'options', [], changeAll);
              this.props.rerender();
            }
          });
        } else {
          if (LINES.length === 1) {
            let LINE = LINES[0];

            Object.keys(LINE).map((one) => {
              if (one !== matchKey) {
                let willStore_val = LINE[one];

                this.changeValByserviceID(one, 'serviceKey', 'val', willStore_val, changeAll);
                this.props.rerender('noQuit');
              }
            });
          }
        }
      } else if (matchKey && childrenNode.type === 'multiselect') {
        let LINES = ARRAY.filter((each) => val.split('#').includes(each[matchKey]));

        if (LINES.length) {
          let KEYS = Object.keys(LINES[0]);

          let OBJ = {};

          KEYS.forEach((each) => {
            OBJ[each] = [...new Set(LINES.map((one) => one[each]))].join(',');
          });

          console.log(OBJ, 'CKAY');

          Object.keys(OBJ).map((one) => {
            if (one !== matchKey) {
              let willStore_val = OBJ[one];

              this.changeValByserviceID(one, 'serviceKey', 'val', willStore_val, changeAll);
              this.props.rerender('noQuit');
            }
          });
        }
      }
    }
  };

  store_all_vals = () => {
    let VALS = this.props.storeVals;

    this.props.VR2_Form_Json.forEach((each, i) => {
      if (each.children && each.children.length) {
        each.children.forEach((each2, j) => {
          if (each2.children && each2.children.length) {
            each2.children.forEach((each3, k) => {
              if (!each3.children && each3.render === 'true') {
                VALS[each3.serviceID] = each3.val;
              }

              if (each3.children && each3.children.length) {
                each3.children.forEach((each4, m) => {
                  if (each4.render === 'true') {
                    VALS[each4.serviceID] = each4.val;
                  }
                });
              }
            });
          }
        });
      }
    });

    this.props.updateStoreVals(VALS);
  };

  Give_Val_By_ServiceKey = (serviceKey) => {
    // console.log(serviceKey, 'Give_Val_By_ServiceKey')

    let VALUE;

    let VR2_Form_Json = this.props.VR2_Form_Json;

    let found = false;

    this.setState(
      {
        ...this.state,
        Give_Val_By_ServiceKey: {
          ...this.state.Give_Val_By_ServiceKey,
          [serviceKey]: ''
        }
      },
      () => {
        VR2_Form_Json.forEach((each, i) => {
          if (found) return VALUE;
          if (each.children && each.children.length) {
            each.children.forEach((each2, j) => {
              if (found) return VALUE;
              if (each2.children && each2.children.length) {
                each2.children.forEach((each3, k) => {
                  if (found) return VALUE;

                  if (!each3.children && each3.render && each3.render === 'true' && each3.serviceKey === serviceKey) {
                    VALUE = each3.val;

                    this.setState({
                      ...this.state,
                      Give_Val_By_ServiceKey: {
                        ...this.state.Give_Val_By_ServiceKey,
                        [serviceKey]: VALUE
                      }
                    });

                    console.log(VALUE, serviceKey, 'Give_Val_By_ServiceKey');

                    if (VALUE) found = true;

                    if (found) return VALUE;
                  }
                });
              }
            });
          }
        });
      }
    );
  };

  input_select_fn = async (childrenNode) => {
    let hit = `${childrenNode.api_info.api_Directory}`;

    console.log('hitting', hit);

    // let pan = await this.Give_Val_By_ServiceKey("Vendor PAN")

    await this.Give_Val_By_ServiceKey('Vendor PAN');
    let VendorPAN = this.state.Give_Val_By_ServiceKey['Vendor PAN'];

    await this.Give_Val_By_ServiceKey('Vendor GST Number');
    let VendorGSTNumber = this.state.Give_Val_By_ServiceKey['Vendor GST Number'];

    //mbp ---uncmtd
    await this.Give_Val_By_ServiceKey('GST Number');
    let GSTNumber = this.state.Give_Val_By_ServiceKey['GST Number'];
    // ----

    let OrgID = this.props.ORG_ID_CONST;

    let input = {
      VendorName: childrenNode.val,
      VendorPAN,
      VendorGSTNumber,
      GSTNumber, //mbp---uncmtd
      OrgID
    };

    console.log(input, 'iNNNNNn_PUT');

    //billExtract_vendor_name_search
    postRequest(hit, input).then((res) => {
      if (res.res.status === 'success') {
        let DATA = res.res.data && res.res.data.length ? JSON.parse(res.res.data) : [];

        if (DATA.length) {
          console.log(DATA);

          this.setState(
            {
              popup_table: {
                search: '',
                data: DATA,
                filteredData: DATA,
                header: Object.keys(DATA[0])
              }
            },
            () => {
              let popup_table = this.state.popup_table;
              //mbp bfr(===)
              if (popup_table && popup_table.data.length && popup_table.data.length === 1) {
                this.tableRow_click(popup_table.data[0]);
              } else if (popup_table && popup_table.data.length) {
                let e = {
                  target: {
                    value: childrenNode.val
                  }
                };

                this.on_popup_table_search(e);
              }
            }
          );
        }
      }
    });
  };

  popup_select_fn = (childrenNode) => {
    this.store_all_vals();

    let SerIDS =
      childrenNode.type === 'popup_select'
        ? ['vendorid', 'vendorgstnumber', 'gstnumber']
        : childrenNode.type === 'popup_select_goods'
          ? ['Order Type', 'Sender ID']
          : [];
    // : childrenNode.type === "popup_select_goods" ? ["POIP Type", "Vendor ID"] : []

    SerIDS = SerIDS.map((each) => this.trim(each));

    let storeVals = this.props.storeVals;

    let obj = {};

    Object.keys(storeVals).forEach((each) => {
      let key = each.split('#')[1];

      if (SerIDS.includes(key)) {
        obj[key] = storeVals[each];
      }
    });

    // billExtract_POIPNumber_popup
    let hit = `${childrenNode.api_info.api_Directory}`;

    console.log('hitting', hit);

    postRequest(hit, obj).then((res) => {
      if (res.res.status === 'success') {
        let DATA = res.res.data && res.res.data.length ? JSON.parse(res.res.data) : [];

        if (DATA.length) {
          console.log(DATA);

          if (this.token === 'BillExtract') {
            // Checks whether an element is even
            const even = (element) => {
              if (element['PO/IP Type'] === 'GoodsReceipt' || element['PO/IP Type'] === 'ServiceReceipt') {
                return element;
              }
            };

            const present = DATA.some(even); // true or false

            if (present) {
              DATA = DATA.map((each) => {
                if (each['PO/IP Type'] === 'PurchaseOrder' || each['PO/IP Type'] === 'InternalPlan') {
                  each.disabled = true;
                }

                return each;
              });
            }
          }

          console.log(DATA);

          this.setState({
            popup_table: {
              search: '',
              data: DATA,
              filteredData: DATA,
              header: Object.keys(DATA[0])
            }
          });
        }
      }
    });
  };

  on_popup_table_search = (e) => {
    const VAL = e.target.value;

    const data = this.state.popup_table.data;

    const filteredData = data.filter((row) =>
      Object.values(row).some(
        (value) =>
          value && value.toString().replace(/\s/g, '').toLowerCase().includes(VAL.replace(/\s/g, '').toLowerCase())
      )
    );

    this.setState({
      ...this.state,
      popup_table: {
        ...this.state.popup_table,
        search: VAL,
        filteredData: filteredData
      }
    });
  };

  //mbp
  //already existed function code
  // tableRow_click = (ROW) => {

  //     console.log(ROW)

  //     Object.keys(ROW).map( one => {

  //         let willStore_val = ROW[one]

  //         if(this.token === "BillExtract" && one === "TAX"){

  //             if(willStore_val === "Non-Taxable"){

  //                 this.changeValByserviceID( "Tax Category", "serviceKey", "val", "NILL-TAX")
  //             }
  //         }

  //         this.changeValByserviceID( one, "serviceKey", "val", willStore_val)
  //     })

  //     this.setState({ open_popup_select: false, popup_table: false})
  //     // this.setState({ open_popup_select: false})
  //     this.props.rerender("noQuit")

  // }

  splitNames = (name) => {
    let cleanedName = name.toLowerCase().replace(/[^a-z0-9\s.-]/g, '');
    cleanedName = cleanedName.replace(/[.-]/g, ' ');
    cleanedName = cleanedName.replace(/\s+/g, ' ').trim();
    console.log('splitname result', cleanedName);
    return cleanedName.split(' ');
  };

  expandAbbreviations = (name) => {
    const name1 = name.toLowerCase();
    const replacements = {
      corp: 'corporation',
      pvt: 'private',
      ltd: 'limited'
    };
    const expandedname = name1.replace(/\b(corp|pvt|ltd)\b/g, (match) => replacements[match]);
    // console.log("expanded names", expandedname);
    return expandedname;
  };

  // Updated calculateSimilarity function to consider all parts and return the highest similarity
  calculateHighestSimilarity = (sourceName, targetName) => {
    try {
      // console.log(sourceName, targetName);
      const sourceParts = this.splitNames(this.expandAbbreviations(sourceName));
      const targetParts = this.splitNames(this.expandAbbreviations(targetName));
      // console.log('sourceParts:', sourceParts);
      // console.log('targetParts:', targetParts);

      const sourceSet = new Set(sourceParts);
      const targetSet = new Set(targetParts);

      let matchCount = 0;
      for (const word of sourceSet) {
        if (targetSet.has(word)) {
          matchCount++;
        }
      }
      const totalWords = new Set([...sourceSet, ...targetSet]).size;
      // console.log(totalWords)
      const similarityPercentage = (matchCount / totalWords) * 100;
      // console.log(similarityPercentage)
      return similarityPercentage;
    } catch (error) {
      console.error('Error calculating similarity:', error);
      return 0; // or some default value
    }
  };

  // Updated handleCheckSimilarity function to use the new calculateHighestSimilarity logic
  handleCheckSimilarity = (name1, name2, name3) => {
    const similarity12 = this.calculateHighestSimilarity(name1, name2);
    const similarity13 = this.calculateHighestSimilarity(name1, name3);
    const similarity23 = this.calculateHighestSimilarity(name2, name3);
    this.setState({
      similarity12: Math.ceil(similarity12),
      similarity13: Math.ceil(similarity13),
      similarity23: Math.ceil(similarity23),
      showSimilarityPopup: true
    });
  };

  // Example ROW click handler
  tableRow_click = (ROW) => {
    let vendorname = localStorage.getItem('billvendorname');
    // console.log(vendorname);

    this.setState(
      {
        selectedVendorName: ROW['Vendor Name As Per Master'],
        selectedLedgerName: ROW['Vendor Ledger Name'],
        ROW
      },
      () => {
        // console.log("state ledgername", this.state.selectedLedgerName);
        // console.log("state vendor name", this.state.selectedVendorName);
        // console.log("selected row in the search popup", ROW);
        // console.log('selected vendorname', ROW['Vendor Name']);

        this.handleCheckSimilarity(vendorname, this.state.selectedVendorName, this.state.selectedLedgerName);
      }
    );
  };

  //mbp
  handlePopupResponse = (response) => {
    if (response === 'yes') {
      this.setState({ showSimilarityPopup: false }, () => {
        this.proceedWithRowClick();
      });
    } else {
      this.setState({ showSimilarityPopup: false });
    }
  };
  //mbp
  proceedWithRowClick = () => {
    const ROW = this.state.ROW;
    Object.keys(ROW).map((one) => {
      let willStore_val = ROW[one];

      if (this.token === 'BillExtract' && one === 'TAX') {
        if (willStore_val === 'Non-Taxable') {
          this.changeValByserviceID('Tax Category', 'serviceKey', 'val', 'NILL-TAX');
        }
      }

      this.changeValByserviceID(one, 'serviceKey', 'val', willStore_val);
    });

    this.setState({
      similarityresult12: this.state.similarity12
    });

    this.setState({ open_popup_select: false, popup_table: false });
    this.props.rerender('noQuit');
  };

  // handleButtonClick =()=>{
  //     ReactDOM.render(
  //         <NewTabComponent
  //         screenName = {this.state.screenInfo.screenName}
  //         screenAction = {this.state.screenInfo.screenAction}
  //         onBack ={this.onBack}
  //         />,
  //         document.createElement('div')
  //     )
  // }

  onBack = () => {};

  render() {
    const popup_table = this.state.popup_table;
    const open_popup_select = this.state.open_popup_select;
    console.log('popup table data', popup_table); ///mbp
    const { similarity12 } = this.state;
    console.log(this.props, 'props');
    console.log(this.props.ORG_ID_CONST, 'orgid');
    return (
      <div>
        <Spinner open={this.state.showSpinner} />

        {/* mbp  stylings in the popup.css imported in the simplepopup component*/}
        {this.state.showSimilarityPopup && (
          <div className="modal-overlay">
            <div className="modal-box">
              <table className="similarity-table">
                <caption>Match Analysis</caption>
                <thead>
                  <tr>
                    <th>% Match</th>
                    <th>Name As Per</th>
                    <th>Name As Per</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{this.state.similarity13.toFixed(2)}%</td>
                    <td>Bill [{localStorage.getItem('billvendorname')}]</td>
                    <td>ERP Ledger [{this.state.selectedLedgerName}]</td>
                  </tr>
                  <tr>
                    <td>{this.state.similarity12.toFixed(2)}%</td>
                    <td>Bill [{localStorage.getItem('billvendorname')}]</td>
                    <td>Vendor Master [{this.state.selectedVendorName}]</td>
                  </tr>
                  <tr>
                    <td>{this.state.similarity23.toFixed(2)}%</td>
                    <td>ERP Ledger [{this.state.selectedLedgerName}]</td>
                    <td>Vendor Master [{this.state.selectedVendorName}]</td>
                  </tr>
                </tbody>
              </table>

              <p className="text-center">
                <em>Do you want to proceed ?</em>{' '}
                <span>
                  <button onClick={() => this.handlePopupResponse('yes')}>Yes</button>
                  <button onClick={() => this.handlePopupResponse('no')}>No</button>
                </span>
              </p>
            </div>
          </div>
        )}

        <div className="view-container">
          {this.props.tree.children.map((treenode, index) => {
            if (treenode.type === 'section' && treenode.view === 'form') {
              {
                /* return (<div className='form-container' key={index}> */
              }
              return (
                <div key={index}>
                  <div className="section-title">{treenode.title}</div>

                  <form>
                    {treenode.children.map((childrenNode, index) => {
                      if (childrenNode.render === 'true') {
                        if (
                          childrenNode.type === 'select' &&
                          childrenNode.options === undefined &&
                          !childrenNode.dynamic_option
                        ) {
                          this.option_undef(childrenNode);
                        }
                        // if( (childrenNode.type === "select" || childrenNode.type === "multiselect") && childrenNode.dynamic_option && childrenNode.dynamic_option.api_Directory && !this.state.dynamicOption.includes(childrenNode.serviceKey))
                        if (
                          (childrenNode.type === 'select' || childrenNode.type === 'multiselect') &&
                          childrenNode.dynamic_option &&
                          childrenNode.dynamic_option.api_Directory &&
                          !this.props.dynamicOption_Done.includes(childrenNode.serviceKey)
                        ) {
                          // this.setState(prevState => ({
                          //     dynamicOption: [...prevState.dynamicOption, childrenNode.serviceKey]
                          // }), () => {
                          //       this.dynamic_option_fn(childrenNode)
                          //   })

                          let dynamicOption_Done = this.props.dynamicOption_Done;

                          dynamicOption_Done.push(childrenNode.serviceKey);

                          this.props.updateDynamicOption_Done(dynamicOption_Done);
                          this.dynamic_option_fn(childrenNode);
                        }
                        // if(childrenNode.type === 'input' && childrenNode.subtype === 'date' && childrenNode.range_fn && childrenNode.range_fn === "current_date" && !childrenNode.val)
                        // {
                        //     this.current_date(childrenNode)
                        // }
                        // if(childrenNode.type === 'input' && childrenNode.subtype === 'date' && childrenNode.range_fn && childrenNode.range_fn === "yesterday_date" && !childrenNode.val)
                        // {
                        //     this.yesterday_date(childrenNode)
                        // }
                        // if(childrenNode.type === 'input' && childrenNode.subtype === 'text' && childrenNode.range_fn && childrenNode.range_fn === "get_user_email" && !childrenNode.val)
                        // {
                        //     this.get_user_email(childrenNode)
                        // }
                        // if(childrenNode.type === 'popup_select' && !this.state.popup_select.includes(index))
                        // {
                        //     this.setState(prevState => ({
                        //         popup_select: [...prevState.popup_select, index]
                        //     }), () => {
                        //         this.popup_select_fn(childrenNode)
                        //       })
                        // }

                        return (
                          <fieldset
                            key={index}
                            style={{ marginTop: 0, paddingTop: 0 }}
                            className={
                              childrenNode.children !== undefined
                                ? 'field-container col-lg-12 group-container'
                                : 'field-container col-lg-12'
                            }>
                            {childrenNode.children === undefined &&
                            childrenNode.type === 'input' &&
                            childrenNode.subtype !== 'null' &&
                            childrenNode.subtype !== 'date' &&
                            childrenNode.subtype !== 'radio' &&
                            !childrenNode.subtype.includes('upload') ? (
                              <div className="row">
                                <div className="col-lg-3">
                                  <Tooltip title={childrenNode.tooltip ? childrenNode.tooltip : childrenNode.title}>
                                    <span>
                                      {childrenNode.title}:{' '}
                                      <span style={{ color: 'red' }}>
                                        {childrenNode.mandatory === 'true' ? '*' : null}
                                      </span>
                                    </span>
                                  </Tooltip>
                                </div>

                                <input
                                  type={childrenNode.subtype}
                                  className="col-lg-3"
                                  ref={childrenNode.serviceID}
                                  name={childrenNode.serviceKey}
                                  id={childrenNode.serviceID}
                                  value={childrenNode.val ? childrenNode.val : ''}
                                  onBlur={this.onBlur}
                                  onChange={(e) => this.myChangeHandler(e, childrenNode)}
                                  disabled={childrenNode.editable && childrenNode.editable === 'false'}
                                />
                              </div>
                            ) : childrenNode.children === undefined &&
                              childrenNode.type === 'input' &&
                              childrenNode.subtype === 'date' ? (
                              <div className="row">
                                <div className="col-lg-3">
                                  <Tooltip title={childrenNode.tooltip ? childrenNode.tooltip : childrenNode.title}>
                                    <span>
                                      {childrenNode.title}:{' '}
                                      <span style={{ color: 'red' }}>
                                        {childrenNode.mandatory === 'true' ? '*' : null}
                                      </span>
                                    </span>
                                  </Tooltip>
                                </div>

                                <input
                                  type={childrenNode.subtype}
                                  className="col-lg-3"
                                  ref={childrenNode.serviceID}
                                  name={childrenNode.serviceKey}
                                  id={childrenNode.serviceID}
                                  value={childrenNode.val ? this.date_DD_MM_YYYY_TO_YYYY_MM_DD(childrenNode) : ''}
                                  onBlur={this.onBlur}
                                  onChange={(e) => this.myChangeHandler(e, childrenNode, 'date')}
                                  disabled={childrenNode.editable && childrenNode.editable === 'false'}
                                />
                              </div>
                            ) : childrenNode.children === undefined && childrenNode.type === 'textarea' ? (
                              <div className="row">
                                <div className="col-lg-3">
                                  <Tooltip title={childrenNode.tooltip ? childrenNode.tooltip : childrenNode.title}>
                                    <span>
                                      {childrenNode.title}:{' '}
                                      <span style={{ color: 'red' }}>
                                        {childrenNode.mandatory === 'true' ? '*' : null}
                                      </span>
                                    </span>
                                  </Tooltip>
                                </div>

                                <textarea
                                  type={childrenNode.subtype}
                                  className="col-lg-3"
                                  ref={childrenNode.serviceID}
                                  name={childrenNode.serviceKey}
                                  id={childrenNode.serviceID}
                                  value={childrenNode.val}
                                  onBlur={this.onBlur}
                                  onChange={(e) => this.myChangeHandler(e, childrenNode)}
                                  rows="4"
                                  cols="50"
                                  disabled={childrenNode.editable && childrenNode.editable === 'false'}
                                />
                              </div>
                            ) : childrenNode.children === undefined &&
                              childrenNode.type === 'input' &&
                              childrenNode.subtype === 'radio' ? (
                              <div className="row">
                                <div className="col-lg-3">
                                  <Tooltip title={childrenNode.tooltip ? childrenNode.tooltip : childrenNode.title}>
                                    <span>
                                      {childrenNode.title}:{' '}
                                      <span style={{ color: 'red' }}>
                                        {childrenNode.mandatory === 'true' ? '*' : null}
                                      </span>
                                    </span>
                                  </Tooltip>
                                </div>

                                <div className="col-lg-3">
                                  <div className="row">
                                    {childrenNode.values &&
                                      childrenNode.values.length &&
                                      childrenNode.values.map((each, index) => {
                                        return (
                                          <div className="col-lg-6">
                                            <input
                                              type={childrenNode.subtype}
                                              name={childrenNode.serviceKey}
                                              id={childrenNode.serviceID}
                                              value={each}
                                              key={each}
                                              checked={each === childrenNode.val}
                                              onBlur={this.onBlur}
                                              onChange={(e) => this.myChangeHandler(e, childrenNode)}
                                              disabled={childrenNode.editable && childrenNode.editable === 'false'}
                                            />
                                            {each}
                                          </div>
                                        );
                                      })}
                                  </div>
                                </div>
                              </div>
                            ) : childrenNode.children === undefined && childrenNode.type === 'input_search' ? (
                              <div className="row">
                                <div className="col-lg-3">
                                  <Tooltip title={childrenNode.tooltip ? childrenNode.tooltip : childrenNode.title}>
                                    <span>
                                      {childrenNode.title}:{' '}
                                      <span style={{ color: 'red' }}>
                                        {childrenNode.mandatory === 'true' ? '*' : null}
                                      </span>
                                    </span>
                                  </Tooltip>
                                </div>

                                <input
                                  type={childrenNode.subtype}
                                  className="col-lg-3"
                                  ref={childrenNode.serviceID}
                                  name={childrenNode.serviceKey}
                                  id={childrenNode.serviceID}
                                  value={childrenNode.val ? childrenNode.val : ''}
                                  onBlur={this.onBlur}
                                  onChange={(e) => this.myChangeHandler(e, childrenNode)}
                                />
                                <button
                                  className="btn btn-link"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.input_select_fn(childrenNode);
                                    this.setState({ open_popup_select: true });
                                  }}>
                                  Search&nbsp;{childrenNode.title}
                                </button>
                                {/* mbp */}
                                <button onClick={this.handleButtonClick}>create vendor</button>
                                {open_popup_select && popup_table && (
                                  <Popup
                                    content={
                                      <>
                                        <div className="row m-4">
                                          <input
                                            type="text"
                                            autoFocus
                                            className="col-lg-6"
                                            name="search"
                                            placeholder="search here"
                                            value={popup_table.search}
                                            onChange={this.on_popup_table_search}
                                          />
                                        </div>

                                        <div className="row m-4">
                                          {!popup_table.filteredData.length ? (
                                            <div>No match found</div>
                                          ) : (
                                            <Table
                                              striped
                                              bordered
                                              hover>
                                              <thead>
                                                <tr>
                                                  {popup_table.header.map((each, indx) => {
                                                    return <th key={indx}>{each}</th>;
                                                  })}
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {popup_table.filteredData.map((each, index) => {
                                                  return (
                                                    <tr
                                                      key={index}
                                                      onClick={() => this.tableRow_click(each)}>
                                                      {popup_table.header.map((one, indx) => {
                                                        return <td>{each[one]} </td>;
                                                      })}
                                                    </tr>
                                                  );
                                                })}
                                              </tbody>
                                            </Table>
                                          )}
                                        </div>
                                      </>
                                    }
                                    handleClose={() => this.setState({ open_popup_select: false, popup_table: false })}
                                    // handleClose={() => this.setState({ open_popup_select : false}) }
                                  />
                                )}
                              </div>
                            ) : //mbp
                            childrenNode.children === undefined &&
                              childrenNode.type === 'input_vendormasternamestatus' ? (
                              <div className="row   align-items-center ">
                                <div className="col-lg-3">
                                  <Tooltip title={childrenNode.tooltip ? childrenNode.tooltip : childrenNode.title}>
                                    <span>
                                      {childrenNode.title}:{' '}
                                      <span style={{ color: 'red' }}>
                                        {childrenNode.mandatory === 'true' ? '*' : null}
                                      </span>
                                    </span>
                                  </Tooltip>
                                </div>

                                <input
                                  type={childrenNode.subtype}
                                  className="col-lg-3"
                                  ref={childrenNode.serviceID}
                                  name={childrenNode.serviceKey}
                                  id={childrenNode.serviceID}
                                  value={childrenNode.val ? childrenNode.val : ''}
                                  disabled={childrenNode.editable && childrenNode.editable === 'false'} //mbp
                                  onBlur={this.onBlur}
                                />

                                <div className="col-lg-3 px-2 d-flex align-items-center">
                                  <Tooltip
                                    title={`${this.state.similarityresult12}%  match between Vendor Name and Vendor Name As Per Mater`}>
                                    <span>
                                      <ProgressComponent percentage={this.state.similarityresult12} />
                                    </span>
                                  </Tooltip>
                                </div>
                              </div>
                            ) : //mbp
                            childrenNode.children === undefined && childrenNode.type === 'input_ledgernamestatus' ? (
                              <div className="row">
                                <div className="col-lg-3">
                                  <Tooltip title={childrenNode.tooltip ? childrenNode.tooltip : childrenNode.title}>
                                    <span>
                                      {childrenNode.title}:{' '}
                                      <span style={{ color: 'red' }}>
                                        {childrenNode.mandatory === 'true' ? '*' : null}
                                      </span>
                                    </span>
                                  </Tooltip>
                                </div>

                                <input
                                  type={childrenNode.subtype}
                                  className="col-lg-3"
                                  ref={childrenNode.serviceID}
                                  name={childrenNode.serviceKey}
                                  id={childrenNode.serviceID}
                                  value={childrenNode.val ? childrenNode.val : ''}
                                  disabled={childrenNode.editable && childrenNode.editable === 'false'} //mbp
                                  onBlur={this.onBlur}
                                />

                                <button>Status</button>
                              </div>
                            ) : //

                            childrenNode.children === undefined && childrenNode.type.includes('popup_select') ? (
                              <div className="row">
                                <div className="col-lg-3">
                                  <Tooltip title={childrenNode.tooltip ? childrenNode.tooltip : childrenNode.title}>
                                    <span>
                                      {childrenNode.title}:{' '}
                                      <span style={{ color: 'red' }}>
                                        {childrenNode.mandatory === 'true' ? '*' : null}
                                      </span>
                                    </span>
                                  </Tooltip>
                                </div>
                                <input
                                  type={childrenNode.subtype}
                                  className="col-lg-3"
                                  ref={childrenNode.serviceID}
                                  name={childrenNode.serviceKey}
                                  id={childrenNode.serviceID}
                                  value={childrenNode.val ? childrenNode.val : ''}
                                  onBlur={this.onBlur}
                                  onChange={(e) => this.myChangeHandler(e, childrenNode)}
                                  disabled
                                />
                                <button
                                  className="btn btn-link"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.popup_select_fn(childrenNode);
                                    this.setState({ open_popup_select: true });
                                  }}>
                                  Search PO/IP
                                </button>

                                {open_popup_select && popup_table && (
                                  <Popup
                                    content={
                                      <>
                                        <div className="row m-4">
                                          <input
                                            type="text"
                                            autoFocus
                                            className="col-lg-6"
                                            name="search"
                                            placeholder="search here"
                                            value={popup_table.search}
                                            onChange={this.on_popup_table_search}
                                          />
                                        </div>

                                        <div className="row m-4">
                                          {!popup_table.filteredData.length ? (
                                            <div>No match found</div>
                                          ) : (
                                            <Table
                                              striped
                                              bordered
                                              hover>
                                              <thead>
                                                <tr>
                                                  {popup_table.header.map((each, indx) => {
                                                    return <th key={indx}>{each}</th>;
                                                  })}
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {popup_table.filteredData.map((each, index) => {
                                                  return (
                                                    <tr
                                                      key={index}
                                                      onClick={() => {
                                                        if (!each.disabled) this.tableRow_click(each);
                                                      }}
                                                      style={
                                                        each.disabled ? { opacity: 0.5, pointerEvents: 'none' } : null
                                                      }>
                                                      {popup_table.header.map((one, indx) => {
                                                        return <td>{each[one]} </td>;
                                                      })}
                                                    </tr>
                                                  );
                                                })}
                                              </tbody>
                                            </Table>
                                          )}
                                        </div>
                                      </>
                                    }
                                    handleClose={() => this.setState({ open_popup_select: false, popup_table: false })}
                                    // handleClose={() => this.setState({ open_popup_select : false}) }
                                  />
                                )}
                              </div>
                            ) : childrenNode.children === undefined && childrenNode.type === 'select' ? (
                              <div className="row">
                                <div className="col-lg-3">
                                  <Tooltip title={childrenNode.tooltip ? childrenNode.tooltip : childrenNode.title}>
                                    <span>
                                      {childrenNode.title}:{' '}
                                      <span style={{ color: 'red' }}>
                                        {childrenNode.mandatory === 'true' ? '*' : null}
                                      </span>
                                    </span>
                                  </Tooltip>
                                </div>

                                {!childrenNode.options || childrenNode.options.length < 10 ? (
                                  <select
                                    className="col-lg-3"
                                    name={childrenNode.serviceKey}
                                    id={childrenNode.serviceID}
                                    value={childrenNode.val}
                                    disabled={childrenNode.editable && childrenNode.editable === 'false'}
                                    onChange={(e) => {
                                      this.onDropDownValueChange(e);
                                      if (childrenNode.onChange_operation)
                                        this.onChange_operation(childrenNode, e.target.value);
                                    }}>
                                    <option value={''}></option>
                                    {childrenNode.options !== undefined &&
                                      childrenNode.options &&
                                      childrenNode.options.map((opt, optKey) => (
                                        <option
                                          key={optKey}
                                          value={opt.value}>
                                          {opt.label}
                                        </option>
                                      ))}
                                  </select>
                                ) : (
                                  <Select
                                    className="col-lg-3"
                                    name={childrenNode.serviceKey}
                                    id={childrenNode.serviceID}
                                    isDisabled={childrenNode.editable && childrenNode.editable === 'false'}
                                    style={{ padding: '0 0' }}
                                    onChange={(value) => {
                                      console.log(value);
                                      this.onDropDownValueChange({
                                        target: {
                                          name: childrenNode.serviceKey,
                                          id: childrenNode.serviceID,
                                          value: value.value
                                        }
                                      });
                                      if (childrenNode.onChange_operation)
                                        this.onChange_operation(childrenNode, value.value);
                                    }}
                                    value={
                                      childrenNode.options && childrenNode.options.length
                                        ? childrenNode.options.find((each) => each.value === childrenNode.val)
                                        : null
                                    }
                                    options={childrenNode.options}
                                    isSeachable={true}
                                    isMulti={false}
                                  />
                                )}

                                {/* <Autocomplete
                                        disablePortal
                                        id={childrenNode.serviceID}
                                        options={childrenNode.options !== undefined && childrenNode.options.length ? childrenNode.options : []}
                                        // sx={{ width: 300 }}
                                        className='col-lg-3'
                                        value={childrenNode.val}
                                        disabled={childrenNode.editable && childrenNode.editable === 'false' }
                                        onChange = {
                                            (event, newValue) => {
                                                this.onDropDownValueChange({
                                                    target: {
                                                        name: childrenNode.serviceKey,
                                                        id: childrenNode.serviceID,
                                                        value: newValue.value
                                                    }
                                                })
                                                if (childrenNode.onChange_operation) {
                                                    this.onChange_operation(childrenNode, newValue.value);
                                                }
                                            }
                                        }
                                        renderInput={(params) => <TextField {...params} label={childrenNode.serviceKey} />}
                                    /> */}
                              </div>
                            ) : childrenNode.children === undefined && childrenNode.type === 'multiselect' ? (
                              <div className="row">
                                <div className="col-lg-3">
                                  <Tooltip title={childrenNode.tooltip ? childrenNode.tooltip : childrenNode.title}>
                                    <span>
                                      {childrenNode.title}:{' '}
                                      <span style={{ color: 'red' }}>
                                        {childrenNode.mandatory === 'true' ? '*' : null}
                                      </span>
                                    </span>
                                  </Tooltip>
                                </div>

                                <Select
                                  className="col-lg-3"
                                  name={childrenNode.serviceKey}
                                  id={childrenNode.serviceID}
                                  isDisabled={childrenNode.editable && childrenNode.editable === 'false'}
                                  onChange={(value) => {
                                    console.log(value);

                                    let VAL = value.map((each) => each.value);

                                    VAL = VAL.join('#');

                                    console.log(VAL);

                                    this.onDropDownValueChange({
                                      target: {
                                        name: childrenNode.serviceKey,
                                        id: childrenNode.serviceID,
                                        value: VAL
                                      }
                                    });
                                    if (childrenNode.onChange_operation) this.onChange_operation(childrenNode, VAL);
                                  }}
                                  value={
                                    childrenNode.options && childrenNode.options.length
                                      ? childrenNode.options.filter((each) =>
                                          childrenNode.val.split('#').includes(each.value)
                                        )
                                      : null
                                  }
                                  options={childrenNode.options}
                                  isSeachable={true}
                                  isMulti={true}
                                />
                              </div>
                            ) : childrenNode.children === undefined &&
                              childrenNode.type === 'input' &&
                              childrenNode.subtype.includes('upload') ? (
                              <div className="row">
                                <div className="col-lg-3">
                                  <Tooltip title={childrenNode.tooltip ? childrenNode.tooltip : childrenNode.title}>
                                    <span>
                                      {childrenNode.title}:{' '}
                                      <span style={{ color: 'red' }}>
                                        {childrenNode.mandatory === 'true' ? '*' : null}
                                      </span>
                                    </span>
                                  </Tooltip>
                                </div>
                                {/* <input type='file' accept='.pdf' className="col-lg-3" ref={childrenNode.serviceID} name={childrenNode.serviceKey} id={childrenNode.serviceID} 
                                    onChange={e => this.onUpload(e, childrenNode)} 
                                    disabled={childrenNode.editable && childrenNode.editable === 'false' } /> */}

                                <div className="col-lg-3">
                                  <input
                                    type="file"
                                    accept=".pdf"
                                    style={{ display: 'none' }}
                                    id={childrenNode.serviceID + 'l'}
                                    name={childrenNode.serviceKey}
                                    ref={childrenNode.serviceID}
                                    disabled={childrenNode.editable && childrenNode.editable === 'false'}
                                    onChange={(e) => {
                                      let event = {
                                        target: {
                                          id: childrenNode.serviceID,
                                          name: childrenNode.serviceKey,
                                          files: e.target.files
                                        }
                                      };

                                      this.onUpload(event, childrenNode);
                                    }}
                                  />

                                  <label htmlFor={childrenNode.serviceID + 'l'}>
                                    <Button
                                      id={childrenNode.serviceID}
                                      variant="contained"
                                      color="primary"
                                      component="span"
                                      disabled={childrenNode.editable && childrenNode.editable === 'false'}>
                                      Upload{' '}
                                      <span className="ml-2">
                                        <ImFolderUpload />
                                      </span>
                                    </Button>
                                  </label>
                                </div>

                                {childrenNode.val && (
                                  <div className="col-lg-3">
                                    <button
                                      className="btn btn-success"
                                      onClick={(e) => this.openPDF(e, childrenNode)}>
                                      Open
                                    </button>
                                  </div>
                                )}

                                {childrenNode.val &&
                                this.state.popData_PDF &&
                                this.state.showPopUP_PDF &&
                                this.state.showPopUP_PDF === childrenNode.serviceID ? (
                                  <Popup
                                    content={
                                      <>
                                        {/* <iframe src={ URL.createObject.keysURL(this.state[childrenNode.serviceID]) } width="100%" height="500px" /> */}

                                        <iframe
                                          src={(() => {
                                            const { fileName, data } = this.state.popData_PDF;
                                            if (!fileName || !data) return '';
                                            console.log(fileName);
                                            
                                            const ext = fileName.split('.').pop().toLowerCase();
                                            let mimeType = '';
                                            switch (ext) {
                                              case 'pdf':
                                                mimeType = 'application/pdf';
                                                break;
                                              case 'jpeg':
                                                mimeType = 'image/jpeg';
                                                break;
                                              case 'png':
                                                mimeType = 'image/png';
                                                break;
                                              default:
                                                return '';
                                            }
                                            return `data:${mimeType};base64,${data}`;
                                          })()}
                                          title="pdf-viewer"
                                          style={{ width: '100%', height: '500px',display:'block' }}
                                        />
                                      </>
                                    }
                                    handleClose={() => this.setState({ showPopUP_PDF: null })}
                                  />
                                ) : null}
                              </div>
                            ) : childrenNode.children !== undefined && childrenNode.type === 'group' ? (
                              <legend>{childrenNode.title}</legend>
                            ) : null}

                            {childrenNode.children !== undefined
                              ? childrenNode.children.map((grandChild, index) => {
                                  return (
                                    <div
                                      className="row field-container"
                                      key={index}>
                                      <div className="col-lg-3">{grandChild.title}:</div>
                                      <input
                                        type="text"
                                        name={grandChild.serviceKey}
                                        id={childrenNode.serviceID}
                                        className="col-lg-3"
                                        onChange={(e) => this.myChangeHandler(e, childrenNode)}
                                      />
                                    </div>
                                  );
                                })
                              : null}
                          </fieldset>
                        );
                      }
                    })}
                  </form>
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    VR2_Form_Json: state.VR2_Reducer.VR2_Form_Json,
    changeLogs: state.VR2_Reducer.changeLogs,
    storeVals: state.VR2_Reducer.storeVals,
    athenaStorage: state.VR2_Reducer.athenaStorage,
    dependency_json: state.VR2_Reducer.dependency_json,
    dynamicOption_Done: state.VR2_Reducer.dynamicOption_Done,
    ORG_ID_CONST: state.VR2_Reducer.ORG_ID_CONST,
    userData: state.login.userData
  };
}

export default connect(mapStateToProps, {
  handleTreeChange,
  updateChangeLogs,
  updateStoreVals,
  updateAthenaStorage,
  update_one_side_PDF_Key,
  updateDynamicOption_Done,
  Set_ORG_ID_CONST
})(form);
