import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { getRequest, postRequest } from '../../../globalhelper/helper';
import { AlreadyPaidBillsByVendorID } from '../../../redux/AlreadyPaidBillsByVendorID/AlreadyPaidBillsByVendorIDReducer';
import _ from 'lodash';
const Connections = (props) => {
  const [selectedBill, setSelectedBill] = useState([]);
  const [filteredBills, setFilteredBills] = useState([]);
  const [BillsByOrgID, setBillsByOrgID] = useState([]);
  const [DebitedAmt, setDebitAmt] = useState(0);
  const [ConnectedBills, setConnectedBills] = useState({});
  const [preventBtn, setPreventBtn] = useState(false);
  let dispatch = useDispatch();
  useEffect(() => {
    setDebitAmt(props.ROW.Debit);
    let UpdatedBills = [];
    if (props.ROW['VendorID']?.startsWith('VM') && props.ROW['VendorID']) {
      UpdatedBills = props.Bills?.Bills?.filter((bill) => bill.senderaccount === props.ROW['VendorID']);
    } else {
      UpdatedBills = props.Bills?.Bills.filter((bill) => bill.PartyLedgerName === props.ROW['subledgername']);
    }
    UpdatedBills = _.sortBy(UpdatedBills, 'documentnumber');

    setBillsByOrgID(UpdatedBills);
    setFilteredBills(UpdatedBills);

    dispatch(
      AlreadyPaidBillsByVendorID({
        OrgID: props.OrgID,
        VendorID: props.ROW['VendorID'],
        CCID: props.ROW['createdid']
      })
    );
  }, [props.OrgID, props.ROW, props.PATTERN_JSON, dispatch, props.Bills]);

  useEffect(() => {
    fetchConnectedBills();
  }, []);
  const fetchConnectedBills = async () => {
    setConnectedBills((prevState) => ({
      ...prevState,
      isLoading: true,
      Bills: [],
      Error: ''
    }));

    try {
      const response = await getRequest(
        `api/veranda/getAlreadyPaidBillsByVendorID?OrgID=${props.OrgID}&VendorID=${props.ROW.VendorID}&CCID=${props.ROW.createdid}`
      );
      if (response?.res?.data) {
        let bills = JSON.parse(response.res.data);
        bills = _.sortBy(bills, 'documentnumber');

        setConnectedBills((prevState) => ({
          ...prevState,
          Bills: bills
        }));
      } else {
        throw new Error('Invalid response data');
      }
    } catch (err) {
      console.error('Error fetching bills:', err);
      setConnectedBills((prevState) => ({
        ...prevState,
        Error: 'Failed to fetch bills data.'
      }));
    } finally {
      setConnectedBills((prevState) => ({
        ...prevState,
        isLoading: false
      }));
    }
  };

  const filterByDate = (e) => {
    const UpdatedBills = BillsByOrgID.filter((bill) => bill.documentdate.match(e.target.value));
    setFilteredBills(UpdatedBills);
  };
  const filterByBillRef = (e) => {
    const UpdatedBills = BillsByOrgID.filter((bill) => bill.documentref.includes(e.target.value));
    setFilteredBills(UpdatedBills);
  };
  const filterByBillNo = (e) => {
    const UpdatedBills = BillsByOrgID.filter((bill) => bill.createdid.includes(e.target.value));
    setFilteredBills(UpdatedBills);
  };

  const TotalPayableAmount = selectedBill.reduce(
    (sum, bill) => sum + (parseFloat(Math.abs(bill.settlementamount)) || 0),
    0
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPreventBtn(true);

    let requestJSON = [
      {
        ...props.CreditorsAc,
        connectionref: props.ROW.createdid,
        connectioneffectivedate: EffectiveDate
      },
      ...selectedBill.map((bill) => ({
        ...bill,
        settlementstatus:
          parseFloat(Math.abs(bill.Lc_PayayableAmt)) === bill.settlementamount ? 'FullySettled' : 'PartiallySettled',
        connectioneffectivedate: EffectiveDate,
        Debit: props.ROW.Debit,
        Credit: props.ROW.Credit
      }))
    ];

    try {
      const response = await postRequest(`api/veranda/postBillsByOrgID?OrgID=${props.OrgID}`, requestJSON);
      if (response.type === 'success') {
        setPreventBtn(false);
        fetchConnectedBills();
        setSelectedBill([]);
        setBillsByOrgID([]);
        setFilteredBills([]);
        props.setBillsByOrgID({});
        props.fetchBills(props.OrgID);
        props.setOpenDialogue(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChanges = (selBill, value) => {
    setBillsByOrgID((prevState) =>
      prevState.map((bill) =>
        bill.createdid === selBill.createdid
          ? {
              ...bill,
              settlementamount: parseFloat(value)
            }
          : bill
      )
    );

    setFilteredBills((prevState) =>
      prevState.map((bill) =>
        bill.createdid === selBill.createdid ? { ...bill, settlementamount: parseFloat(value) } : bill
      )
    );

    setSelectedBill((prevState) =>
      prevState.map((bill) =>
        bill.createdid === selBill.createdid ? { ...bill, settlementamount: parseFloat(value) } : bill
      )
    );
  };
  const handleSelect = (bill) => {
    setFilteredBills((prevBills) => {
      if (!Array.isArray(prevBills)) {
        console.error('filteredBills is not an array:', prevBills);
        return prevBills;
      }

      return prevBills.map((b) =>
        b.createdid === bill.createdid
          ? {
              ...b,
              IsSelected: !bill.IsSelected,
              settlementamount: parseFloat(Math.abs(bill.Lc_PayayableAmt))
            }
          : b
      );
    });

    setBillsByOrgID((prevBills) => {
      if (!Array.isArray(prevBills)) {
        console.error('BillsByOrgID is not an array:', prevBills);
        return prevBills;
      }

      return prevBills.map((b) =>
        b.createdid === bill.createdid
          ? {
              ...b,
              IsSelected: !bill.IsSelected,
              settlementamount: parseFloat(Math.abs(bill.Lc_PayayableAmt))
            }
          : b
      );
    });

    setSelectedBill((prevSelected) => {
      if (!Array.isArray(prevSelected)) {
        console.error('selectedBill is not an array:', prevSelected);
        return prevSelected;
      }

      if (bill.IsSelected) {
        return prevSelected.filter((selBill) => selBill.createdid !== bill.createdid);
      } else {
        return [
          ...prevSelected,
          {
            ...bill,
            settlementamount: parseFloat(Math.abs(bill.Lc_PayayableAmt)),
            connectionref: props.ROW.createdid
          }
        ];
      }
    });
  };
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const [EffectiveDate, setEffectiveDate] = useState(getTodayDate());
  let CurrentBalance =
    parseFloat(props.ROW['Debit']) -
    parseFloat(ConnectedBills?.Bills?.reduce((sum, bill) => bill.settlementamount + sum, 0).toFixed(1));

  return (
    <>
      <div className="w-100">
        <>
          <div className="w-100 mb-4">
            <Row>
              <Col>
                <label style={{ fontSize: 'l', whiteSpace: 'nowrap' }}>
                  <strong>Vendor ID : </strong>
                  {props.ROW['VendorID']}
                </label>
              </Col>
              <Col>
                <label style={{ fontSize: 'l', whiteSpace: 'nowrap' }}>
                  <strong>Name : </strong>
                  {props.ROW['VendorName']}
                </label>
              </Col>
              <Col className="d-flex col">
                <label
                  style={{ fontSize: 'l', whiteSpace: 'nowrap' }}
                  htmlFor="EffectiveDate">
                  Effective Date : &nbsp;
                </label>
                <input
                  type="date"
                  name="EffectiveDate"
                  value={EffectiveDate}
                  id=""
                  onChange={(e) => setEffectiveDate(e.target.value)}
                />
              </Col>
              <Col className="text-sm-right">
                <p style={{ fontSize: '17px', fontWeight: 'bold' }}>
                  Amount&nbsp;:{parseFloat(props.ROW['Debit']).toFixed(2)}
                </p>
              </Col>
            </Row>
          </div>
          <div>
            <table id="table-css">
              <thead className="table-header">
                <tr>
                  <th>Connection ID</th>
                  <th>Created ID</th>
                  <th>Jonurale NO</th>
                  <th>Accounting Date</th>
                  <th>Amount in Local Currency</th>
                </tr>
              </thead>
              <tbody>
                {ConnectedBills.isLoading ? (
                  <tr>
                    <td colSpan={6}>Loading...</td>
                  </tr>
                ) : ConnectedBills.Bills && ConnectedBills.Bills.length > 0 ? (
                  ConnectedBills.Bills.map((bill) => (
                    <>
                      <tr>
                        <td className="text-sm-left">{bill.connectionid}</td>
                        <td className="text-sm-left">{bill.createdid}</td>
                        <td className="text-sm-left">{bill.accountingdocnumber}</td>
                        <td className="text-sm-right">{bill.accountingdate}</td>
                        <td className="text-sm-right">{parseFloat(bill.settlementamount).toFixed(2)}</td>
                      </tr>
                    </>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={6}
                      style={{ color: 'red' }}>
                      <small>No data found</small>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <br />
            <Form className="w-100 ">
              <Row className="d-flex justify-content-around">
                <Col className="d-flex col">
                  <Form.Label style={{ whiteSpace: 'nowrap' }}>Search Bill NO&nbsp;:&nbsp;</Form.Label>
                  <Form.Control
                    placeholder="Ex:1243545"
                    onChange={filterByBillNo}
                    size={15}
                  />
                </Col>
                <Col className="d-flex col">
                  <Form.Label style={{ whiteSpace: 'nowrap' }}>Invoice ref&nbsp;:&nbsp;</Form.Label>
                  <Form.Control
                    placeholder="Ex:greg4522"
                    onChange={filterByBillRef}
                    size={15}
                  />
                </Col>
                <Col>
                  <Form.Group
                    controlId="formDate"
                    className="d-flex col">
                    <Form.Label style={{ whiteSpace: 'nowrap' }}>Bill dt&nbsp;:&nbsp;</Form.Label>
                    <Form.Control
                      placeholder="Bill reference"
                      type="date"
                      onChange={filterByDate}
                      size={15}
                    />
                  </Form.Group>
                </Col>
                <Col className="text-sm-right">
                  <p style={{ fontSize: '17px', fontWeight: 'bold' }}>
                    Current Balance&nbsp;:&nbsp;
                    {CurrentBalance.toFixed(2)}
                  </p>
                </Col>
              </Row>
            </Form>
          </div>

          <table id="table-css">
            <thead>
              <tr>
                <th>Select Bill</th>
                <th>Bill No</th>
                <th>Invoice Ref</th>
                <th>Invoice Date</th>
                <th>Invoice Amount</th>
                <th>Payable Amount</th>
                <th>Planned Amount</th>
              </tr>
            </thead>
            <tbody>
              {!props.Bills.isLoading ? (
                filteredBills.map((bill, index) => (
                  <>
                    <tr key={index}>
                      <td>
                        <input
                          type="checkbox"
                          checked={bill.IsSelected}
                          disabled={
                            CurrentBalance <= 0 ||
                            (!bill.IsSelected && parseFloat(CurrentBalance) < parseFloat(TotalPayableAmount))
                          }
                          onClick={() => handleSelect(bill)}
                        />
                      </td>
                      <td className="text-sm-left">{bill.createdid}</td>
                      <td className="text-sm-left">{bill.documentref}</td>
                      <td className="text-sm-right">{bill.documentdate}</td>
                      <td className="text-sm-right">{parseFloat(bill.LC_PayAmount).toFixed(2)}</td>
                      <td className="text-sm-right">{parseFloat(bill.Lc_PayayableAmt).toFixed(2)}</td>
                      <td>
                        <input
                          className="small-height-input text-right-input text-sm-right"
                          type="number"
                          step="0.01"
                          size={10}
                          disabled={!bill.IsSelected}
                          style={{ height: '30px', lineHeight: '30px' }}
                          value={bill.IsSelected ? parseFloat(parseFloat(bill.settlementamount).toFixed(2)) : 0}
                          onChange={(e) => handleChanges(bill, e.target.value)}
                          min={0}
                          placeholder={Math.abs(parseFloat(parseFloat(bill.Lc_PayayableAmt).toFixed(2)))}
                        />
                        <br />
                        {selectedBill.length > 0 &&
                        selectedBill[selectedBill.length - 1].createdid === bill.createdid &&
                        parseFloat(CurrentBalance) < parseFloat(TotalPayableAmount) ? (
                          <>
                            <Button
                              className="mt-2"
                              onClick={() =>
                                handleChanges(
                                  bill,
                                  Math.abs((bill.Lc_PayayableAmt - (CurrentBalance - TotalPayableAmount)).toFixed(2))
                                )
                              }>
                              {Math.abs((bill.Lc_PayayableAmt - (CurrentBalance - TotalPayableAmount)).toFixed(2))}
                            </Button>
                            <br />
                            <small className="text-primary">click this button to apply amount</small>
                          </>
                        ) : (
                          ''
                        )}{' '}
                        <br />
                        {bill.settlementamount > Math.abs(bill.Lc_PayayableAmt) ? (
                          <small className="text-danger">Not accpetable</small>
                        ) : (
                          ''
                        )}
                      </td>
                    </tr>
                  </>
                ))
              ) : (
                <>
                  <tr>
                    <td colSpan={7}>Loading...</td>
                  </tr>
                </>
              )}
              {selectedBill.length > 0 ? (
                <tr>
                  <td
                    colSpan="6"
                    className="text-sm-right">
                    <strong>Total</strong>&nbsp;
                  </td>
                  <td className="text-sm-right">
                    {parseFloat(CurrentBalance) < parseFloat(TotalPayableAmount) ? (
                      <>
                        <p>
                          <strong>{TotalPayableAmount.toFixed(2)}</strong>{' '}
                          <span className="text-danger">&gt; {CurrentBalance.toFixed(2)}</span>
                        </p>
                        <p>
                          <strong>{(CurrentBalance - TotalPayableAmount).toFixed(2)}</strong>
                        </p>
                      </>
                    ) : (
                      <p>
                        <strong>{TotalPayableAmount.toFixed(2)}</strong>
                      </p>
                    )}
                  </td>
                </tr>
              ) : (
                ''
              )}
            </tbody>
          </table>
          <br />
          {selectedBill.length > 0 ? (
            <Button
              type="submit"
              onClick={handleSubmit}
              disabled={
                preventBtn ||
                CurrentBalance <= 0 ||
                parseFloat(CurrentBalance) < parseFloat(TotalPayableAmount) ||
                selectedBill.some(
                  (bill) => parseFloat(bill.settlementamount) > parseFloat(Math.abs(bill.Lc_PayayableAmt))
                )
              }>
              Submit
            </Button>
          ) : (
            ''
          )}
          <p>
            {selectedBill.some(
              (bill) => parseFloat(bill.settlementamount) > parseFloat(Math.abs(bill.Lc_PayayableAmt))
            ) +
              ' ' +
              parseFloat(CurrentBalance) <
              parseFloat(TotalPayableAmount) + ' ' + CurrentBalance <=
              0}
          </p>
        </>
      </div>
    </>
  );
};
export default Connections;
