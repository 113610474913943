import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function ValidatePopUp({ show, message, handleClose }) {

  useEffect(() => {
    if (show) {
      // Perform any effect when the modal opens if needed
    }
  }, [show]);

  const getBackgroundColor = (type) => {
    if (type === 'success') return "#2ead0e";
    if (type === 'error') return "#f52d0a";
    if (type === 'warning') return "#0398fc"
    return "white";
  };

  const getMessageElements = () => {
    const messageElements = [];
    if (Array.isArray(message?.success)) {
      message.success.forEach((successmessage) => {
        messageElements.push({
          type: 'success',
          text: successmessage
        });
      });
    } else if (message?.success) {
      messageElements.push({
        type: 'success',
        text: message.success
      });
    }

    if (Array.isArray(message?.error)) {
      message.error.forEach((errormessage) => {
        messageElements.push({
          type: 'error',
          text: errormessage
        });
      });
    } else if (message?.error) {
      messageElements.push({
        type: 'error',
        text: message.error
      });
    }

    if (Array.isArray(message?.warning)) {
      message.warning.forEach((warningmessage) => {
        messageElements.push({
          type: 'warning',
          text: warningmessage
        });
      });
    } else if (message?.warning) {
      messageElements.push({
        type: 'warning',
        text: message.warning
      });
    }

    return messageElements;
  };

  return (
    <Modal show={show} onHide={handleClose} scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Validation Result</Modal.Title>
      </Modal.Header>

      <Modal.Body
        className="modal-body-wrapper"
        style={{
          maxHeight: "400px", // Set maximum height for scrolling
          overflowY: "auto", // Enable vertical scrolling
        }}
      >
        {getMessageElements().map((msg, index) => (
          <div
            key={index}
            className="m-2 p-2"
            style={{
              backgroundColor: getBackgroundColor(msg.type),
              color: "white",
              whiteSpace: "pre-line",
              borderRadius: "5px", // Optional: Add some styling for better visuals
            }}
          >
            {msg.text}
          </div>
        ))}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
